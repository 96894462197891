import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import AuthProvider from "./lib/AuthProvider";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </React.StrictMode>
);
