import PropTypes from "prop-types";

import {
    MdOutlineThumbDown as LowIcon,
    MdOutlineThumbUp as HighIcon,
} from "react-icons/md";

import { LuDot as MediumIcon } from "react-icons/lu";

import { Button } from "konsta/react";

const SelectPriorityButtons = ({
    value,
    onChange,
    enableMedium = false,
    disabled = false,
}) => {
    return (
        <div className="flex space-x-4">
            <Button
                outline
                onClick={() => onChange(value === "High" ? "Medium" : "High")}
                className={`flex items-center k-color-brand-green ${
                    value === "High" && !disabled
                        ? "material:bg-green-500 material:text-black ios:bg-brand-green ios:text-green-800"
                        : ""
                }`}
                disabled={disabled}
            >
                <div className="w-24 flex justify-center">
                    <HighIcon className="size-5" />
                </div>
                {/*<div className="w-16">High</div>*/}
            </Button>

            {enableMedium ? (
                <Button
                    outline
                    onClick={() => {
                        if (value !== "Medium") {
                            onChange("Medium");
                        }
                    }}
                    className={`flex items-center k-color-brand-yellow ${
                        value === "Medium" && !disabled ? "bg-brand-yellow text-white" : ""
                    }`}
                    disabled={disabled}
                >
                    <MediumIcon className="size-5" />
                    <div className="w-16">Medium</div>
                </Button>
            ) : null}

            <Button
                outline
                onClick={() => onChange(value === "Low" ? "Medium" : "Low")}
                className={`flex items-center k-color-brand-red ${
                    value === "Low" && !disabled ? "bg-brand-red text-white" : ""
                }`}
                disabled={disabled}
            >
                <LowIcon className="size-5" />
                {/*<div className="w-16">Low</div>*/}
            </Button>
        </div>
    );
};

SelectPriorityButtons.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    enableMedium: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default SelectPriorityButtons;
