import PropTypes from "prop-types";

import { Page, Block } from "konsta/react";

import { useAuth } from "../../lib/authUtils";
import HomeNavbar from "./HomeNavbar";
import SignIn from "./SignIn";
import TherapistScheduleTemplates2 from "../schedule/TherapistScheduleTemplates2";

const Home = ({ title }) => {
    const auth = useAuth();

    const userIsSignedIn = auth.user !== null;

    if (auth.loading) {
        return (
            <Page>
                <HomeNavbar title={title} />

                <Block>Loading...</Block>
            </Page>
        );
    }

    if (!userIsSignedIn) {
        return (
            <Page>
                <HomeNavbar title={title} />

                <SignIn />
            </Page>
        );
    }

    let name = "";
    if (auth.user?.name) {
        name = auth.user.name;
    } else {
        if (auth.user?.firstName) {
            name = auth.user.firstName;
        }
        if (auth.user?.lastName) {
            name += ` ${auth.user.lastName}`;
        }
    }

    return (
        <Page>
            <HomeNavbar title={title} />

            <Block className="mt-4">
                <h2>Welcome, {name}!</h2>
                <p className="mt-2">Email: {auth.user ? auth.user.email : ""}</p>
            </Block>

            <TherapistScheduleTemplates2 />
        </Page>
    );
};

Home.propTypes = {
    title: PropTypes.string,
};

export default Home;
