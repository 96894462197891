import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import {
    Block,
    BlockTitle,
    List,
    ListItem,
    ListInput,
    Checkbox,
    Badge,
    Button,
    Sheet,
    Toolbar,
} from "konsta/react";

import { formatDate, calculateHash } from "../utils/utils";
import { readDocument } from "lib/db";
import { useAuth } from "lib/authUtils";

const TemplateView = ({
    state,
    dispatch,
    userTemplate,
    therapyScheduleTemplateId,
    templateUrlNumber = "",
}) => {
    const navigate = useNavigate();
    const auth = useAuth();
    const [openClearSchedule, setOpenClearSchedule] = useState(false);
    const [currentHash, setCurrentHash] = useState("");
    const [assignedSchedule, setAssignedSchedule] = useState(null);

    const submittedDate =
        userTemplate && userTemplate.submitted
            ? moment(userTemplate.submittedAt?.toDate()).format("MM/DD/YYYY hh:mm A")
            : "";
    const lastHash = userTemplate ? userTemplate.hash : "";
    const userTemplateJson = JSON.stringify(userTemplate, null, 2);

    const userId = auth?.user?.uid;

    useEffect(() => {
        const getHash = async () => {
            let _userTemplate = JSON.parse(userTemplateJson);
            console.log("_userTemplate", _userTemplate);

            const hash = await calculateHash({
                ..._userTemplate,
                submitted: undefined,
                submittedAt: undefined,
                hash: undefined,
            });

            setCurrentHash(hash);
        };

        getHash();
    }, [userTemplateJson]);

    useEffect(() => {
        const readAssignedSchedule = async () => {
            const doc = await readDocument("assignedSchedule", userId);

            if (doc) {
                const _assignedSchedule = doc.therapistScheduleTemplates.find(
                    (item) => item.therapyScheduleTemplateId === therapyScheduleTemplateId
                );

                console.log("assingedSchedule", _assignedSchedule);

                if (_assignedSchedule) {
                    setAssignedSchedule(_assignedSchedule);
                } else {
                    setAssignedSchedule(null);
                }
            }
        };

        if (userId && therapyScheduleTemplateId) {
            readAssignedSchedule();
        }
    }, [userId, therapyScheduleTemplateId]);

    const closed = state.template.inactive || state.template.status === "Closed";

    return (
        <>
            <Block outlineIos inset className="flex justify-between">
                <p>
                    From {formatDate(state.template.startDate)} to{" "}
                    {formatDate(state.template.endDate)}{" "}
                </p>
                <Badge
                    colors={{
                        bg: closed ? "bg-gray-500" : "bg-primary",
                    }}
                >
                    {closed ? "Closed" : "Open"}
                </Badge>
            </Block>

            <Block>
                <List strong outlineIos inset>
                    <ListInput
                        outline
                        label="Max Days/Week"
                        type="select"
                        dropdown
                        defaultValue=""
                        placeholder="Please choose..."
                        value={state.userTemplate?.maxDaysPerWeek || ""}
                        onChange={(e) => {
                            const value = e.target.value;

                            dispatch({
                                type: "update_user_template",
                                payload: {
                                    maxDaysPerWeek: value,
                                },
                            });
                        }}
                        disabled={closed}
                    >
                        <option value="" disabled>
                            Please choose...
                        </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                    </ListInput>

                    <ListInput
                        outline
                        label="Treatments/Week"
                        type="select"
                        dropdown
                        defaultValue=""
                        placeholder="Please choose..."
                        value={state.userTemplate?.numberOfSlotsPerWeek || ""}
                        onChange={(e) => {
                            const value = e.target.value;

                            dispatch({
                                type: "update_user_template",
                                payload: {
                                    numberOfSlotsPerWeek: value,
                                },
                            });
                        }}
                        disabled={closed}
                    >
                        <option value="" disabled>
                            Please choose...
                        </option>
                        {/* 0 to 35 */}
                        {Array.from(Array(36).keys()).map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </ListInput>

                    <ListItem
                        label
                        title="Consecutive Days"
                        media={
                            <Checkbox
                                component="div"
                                name="consecutiveDays"
                                checked={state.userTemplate?.consecutiveDays || false}
                                onChange={(e) => {
                                    const value = e.target.checked;

                                    dispatch({
                                        type: "update_user_template",
                                        payload: {
                                            consecutiveDays: value,
                                        },
                                    });
                                }}
                                disabled={closed}
                            />
                        }
                    />
                </List>

                <List strong outlineIos inset>
                    <ListItem
                        link
                        header={undefined}
                        footer={""}
                        title={(closed ? "View" : "Edit") + " days of the week"}
                        after={closed ? "View" : "Edit"}
                        className="font-bold"
                        onClick={() =>
                            navigate(
                                `/therapist-schedule-template${templateUrlNumber}/${therapyScheduleTemplateId}/days`
                            )
                        }
                    />
                </List>
            </Block>

            <Block inset className="space-y-28">
                <div>
                    <Button
                        large
                        className="k-color-brand-green"
                        onClick={() => {
                            navigate(
                                `/therapist-schedule-view-and-submit/${therapyScheduleTemplateId}`
                            );
                        }}
                    >
                        {closed ? "View Only" : "View and Submit"}
                    </Button>

                    <div className="mt-4">
                        {submittedDate ? (
                            <p>* Last submitted on: {submittedDate}</p>
                        ) : (
                            <p>* You have not submitted your schedule request yet.</p>
                        )}

                        {submittedDate && (!lastHash || lastHash !== currentHash) ? (
                            <p>
                                * We detected changes in the requested schedule. Please
                                review the changes and submit your request.
                            </p>
                        ) : submittedDate ? (
                            <p>* Your submitted schedule request is up to date.</p>
                        ) : null}
                    </div>

                    {assignedSchedule ? (
                        <div className="mt-8">
                            <Button
                                large
                                className="k-color-brand-green"
                                onClick={() => {
                                    navigate(
                                        `/therapist-schedule-view-assigned/${therapyScheduleTemplateId}`
                                    );
                                }}
                            >
                                View Assigned Schedule
                            </Button>
                        </div>
                    ) : null}
                </div>

                <Button
                    large
                    outline
                    className="k-color-brand-red"
                    onClick={() => {
                        setOpenClearSchedule(true);
                    }}
                    disabled={closed}
                >
                    Clear Schedule
                </Button>
            </Block>

            <Sheet
                className="max-w-[calc(min(100%,460px))] ml-[calc(100%-min(100%,460px))]"
                opened={openClearSchedule}
                onBackdropClick={() => setOpenClearSchedule(false)}
            >
                <Toolbar top>
                    <div className="left" />
                    <div className="right">
                        <Link onClick={() => setOpenClearSchedule(false)}>Cancel</Link>
                    </div>
                </Toolbar>
                <Block>
                    <p>
                        Clear the schedule request for all days of the week? This action
                        cannot be undone.
                    </p>
                    <div className="mt-4 mb-16">
                        <Button
                            large
                            className="k-color-brand-red"
                            onClick={() => setOpenClearSchedule(false)}
                        >
                            Clear Schedule
                        </Button>
                    </div>
                </Block>
            </Sheet>
        </>
    );
};

TemplateView.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    userTemplate: PropTypes.object,
    therapyScheduleTemplateId: PropTypes.string.isRequired,
    templateUrlNumber: PropTypes.string,
};

export default TemplateView;
