import { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Link, useMatch, useNavigate } from "react-router-dom";
import moment from "moment";

import { IoTodayOutline } from "react-icons/io5";

import {
    MdOutlineThumbDown as LowIcon,
    MdOutlineThumbUp as HighIcon,
    MdOutlineNightlight as EveningIcon,
    MdChevronRight as ChevronRightIcon,
} from "react-icons/md";

import { TbSunHigh as MorningIcon, TbSunset2 as AfternoonIcon } from "react-icons/tb";

import { LuDot as MediumIcon } from "react-icons/lu";

import {
    Page,
    Block,
    BlockTitle,
    List,
    ListItem,
    ListInput,
    Checkbox,
    Radio,
    Badge,
    Button,
    Sheet,
    Toolbar,
} from "konsta/react";

import HomeNavbar from "../home/HomeNavbar";

import { readDocument, updateDocument, createDocumentWithId } from "lib/db";
import { useAuth } from "lib/authUtils";
import SelectPriority from "./SelectPriority";
import SelectPriorityButtons from "./SelectPriorityButtons";
import DoubleShiftsButton from "./DoubleShiftsButton";
import TemplateView from "./TemplateView";

const DAYS_OF_WEEK = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

const PARTS_OF_DAY = ["Morning", "Afternoon", "Evening"];

const getTherapistRequestedSchedule = () => {
    const therapistRequestedSchedule = [];

    DAYS_OF_WEEK.forEach((dayOfWeek) => {
        PARTS_OF_DAY.forEach((partOfDay) => {
            therapistRequestedSchedule.push({
                dayOfWeek,
                partOfDay,
                availability: "Available",
            });
        });
    });

    return therapistRequestedSchedule;
};

const timeToHours = (time) => {
    return time.hours() + time.minutes() / 60;
};

const getPartOfDay = (startTime, endTime) => {
    // if the start time is before noon, it is the morning shift
    // if the end time is after 5pm, it is the evening shift
    // otherwise, it is the afternoon shift

    const start = timeToHours(moment(startTime, "HH:mm"));
    const end = timeToHours(moment(endTime, "HH:mm"));

    if (start < 12) {
        return "Morning";
    } else if (end > 17) {
        return "Evening";
    } else {
        return "Afternoon";
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case "set":
            return {
                ...state,
                ...action.payload,
            };

        case "update_user_template": {
            const userTemplate = {
                ...state.userTemplate,
                ...action.payload,
            };

            updateDocument("users", state.user.uid, {
                therapistScheduleTemplates: [
                    ...state.user.therapistScheduleTemplates.filter(
                        (item) =>
                            item.therapyScheduleTemplate_link !==
                            userTemplate.therapyScheduleTemplate_link
                    ),
                    userTemplate,
                ],
            });

            return { ...state, userTemplate };
        }

        case "update_user_request": {
            const { dayOfWeek, partOfDay, payload } = action.payload;

            let therapistRequestedSchedule = [];
            if (state.userTemplate.therapistRequestedSchedule) {
                therapistRequestedSchedule = JSON.parse(
                    JSON.stringify(state.userTemplate.therapistRequestedSchedule)
                );
            }

            const index = therapistRequestedSchedule.findIndex(
                (r) => r.dayOfWeek === dayOfWeek && r.partOfDay === partOfDay
            );

            if (index > -1) {
                therapistRequestedSchedule[index] = {
                    ...therapistRequestedSchedule[index],
                    ...payload,
                };
            } else {
                therapistRequestedSchedule.push({
                    dayOfWeek,
                    partOfDay,
                    ...payload,
                });
            }

            updateDocument("users", state.user.uid, {
                therapistScheduleTemplates: [
                    ...state.user.therapistScheduleTemplates.filter(
                        (item) =>
                            item.therapyScheduleTemplate_link !==
                            state.userTemplate.therapyScheduleTemplate_link
                    ),
                    {
                        ...state.userTemplate,
                        therapistRequestedSchedule,
                    },
                ],
            });

            return {
                ...state,
                userTemplate: {
                    ...state.userTemplate,
                    therapistRequestedSchedule,
                },
            };
        }

        case "toggle_user_requests": {
            const { dayOfWeek } = action.payload;

            let therapistRequestedSchedule = [];
            if (state.userTemplate.therapistRequestedSchedule) {
                therapistRequestedSchedule = JSON.parse(
                    JSON.stringify(state.userTemplate.therapistRequestedSchedule)
                );
            }

            const dayRequests = therapistRequestedSchedule.filter(
                (r) => r.dayOfWeek === dayOfWeek
            );

            if (dayRequests.length > 0) {
                const available = dayRequests.some((r) => r.availability === "Available");

                //console.log("available", available, JSON.stringify(dayRequests));

                if (available) {
                    dayRequests.forEach((r) => {
                        r.availability = "Not Available";
                    });
                } else {
                    dayRequests.forEach((r) => {
                        r.availability = "Available";
                    });
                }
            } else {
                therapistRequestedSchedule.push({
                    dayOfWeek,
                    partOfDay: "Morning",
                    availability: "Available",
                });

                therapistRequestedSchedule.push({
                    dayOfWeek,
                    partOfDay: "Afternoon",
                    availability: "Available",
                });

                therapistRequestedSchedule.push({
                    dayOfWeek,
                    partOfDay: "Evening",
                    availability: "Available",
                });
            }

            //console.log("update", state.userTemplate, therapistRequestedSchedule);

            updateDocument("users", state.user.uid, {
                therapistScheduleTemplates: [
                    ...state.user.therapistScheduleTemplates.filter(
                        (item) =>
                            item.therapyScheduleTemplate_link !==
                            state.userTemplate.therapyScheduleTemplate_link
                    ),
                    {
                        ...state.userTemplate,
                        therapistRequestedSchedule,
                    },
                ],
            });

            return {
                ...state,
                userTemplate: {
                    ...state.userTemplate,
                    therapistRequestedSchedule,
                },
            };
        }

        /*case "update_all_week_request": {
            const { payload } = action;
            const { partOfDay, availability } = payload;

            console.log("update_all_week_request", payload);

            let therapistRequestedSchedule = [];
            if (state.userTemplate.therapistRequestedSchedule) {
                therapistRequestedSchedule = JSON.parse(
                    JSON.stringify(state.userTemplate.therapistRequestedSchedule)
                );
            }

            DAYS_OF_WEEK.forEach((dayOfWeek) => {
                const request = therapistRequestedSchedule.find(
                    (r) => r.dayOfWeek === dayOfWeek && r.partOfDay === partOfDay
                );

                if (request) {
                    request.availability = availability;
                } else {
                    therapistRequestedSchedule.push({
                        dayOfWeek,
                        partOfDay,
                        availability,
                    });
                }
            });

            updateDocument("users", state.user.uid, {
                therapistScheduleTemplates: [
                    ...state.user.therapistScheduleTemplates.filter(
                        (item) =>
                            item.therapyScheduleTemplate_link !==
                            state.userTemplate.therapyScheduleTemplate_link
                    ),
                    {
                        ...state.userTemplate,
                        therapistRequestedSchedule,
                    },
                ],
            });

            return {
                ...state,
                userTemplate: {
                    ...state.userTemplate,
                    therapistRequestedSchedule,
                },
            };
        }*/

        case "update_user_shift": {
            const {
                dayOfWeek,
                partOfDay,
                startTime,
                endTime,
                //slots,
                //priority,
                therapyRoomId,
                //therapyScheduleTemplateItemId,
                //enable,
            } = action.payload;

            let therapistRequestedSchedule = [];
            if (state.userTemplate.therapistRequestedSchedule) {
                therapistRequestedSchedule = JSON.parse(
                    JSON.stringify(state.userTemplate.therapistRequestedSchedule)
                );
            }

            const index = therapistRequestedSchedule.findIndex(
                (r) => r.dayOfWeek === dayOfWeek && r.partOfDay === partOfDay
            );

            if (index > -1) {
                if (!therapistRequestedSchedule[index].therapistRequestedScheduleShift) {
                    therapistRequestedSchedule[index].therapistRequestedScheduleShift = [];
                }

                const shiftIndex = therapistRequestedSchedule[
                    index
                ].therapistRequestedScheduleShift.findIndex(
                    (s) =>
                        s.startTime === startTime &&
                        s.endTime === endTime &&
                        s.therapyRoomId === therapyRoomId
                );

                if (shiftIndex > -1) {
                    therapistRequestedSchedule[index].therapistRequestedScheduleShift[
                        shiftIndex
                    ] = {
                        ...therapistRequestedSchedule[index]
                            .therapistRequestedScheduleShift[shiftIndex],
                        ...action.payload,
                    };
                } else {
                    therapistRequestedSchedule[index].therapistRequestedScheduleShift.push(
                        action.payload
                    );
                }

                updateDocument("users", state.user.uid, {
                    therapistScheduleTemplates: [
                        ...state.user.therapistScheduleTemplates.filter(
                            (item) =>
                                item.therapyScheduleTemplate_link !==
                                state.userTemplate.therapyScheduleTemplate_link
                        ),
                        {
                            ...state.userTemplate,
                            therapistRequestedSchedule,
                        },
                    ],
                });
            }

            return {
                ...state,
                userTemplate: {
                    ...state.userTemplate,
                    therapistRequestedSchedule,
                },
            };
        }

        default:
            return state;
    }
};

const TherapistScheduleTemplate2 = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const matchId = useMatch({
        path: "/therapist-schedule-template2/:therapyScheduleTemplateId",
    });
    const matchDays = useMatch({
        path: "/therapist-schedule-template2/:therapyScheduleTemplateId/:daysId",
    });
    const matchDayOfWeek = useMatch({
        path: "/therapist-schedule-template2/:therapyScheduleTemplateId/:daysId/:dayOfWeek",
    });
    const matchPartOfDay = useMatch({
        path: "/therapist-schedule-template2/:therapyScheduleTemplateId/:daysId/:dayOfWeek/:partOfDay",
    });
    const [state, dispatch] = useReducer(reducer, {
        user: {},
        template: {},
        userTemplate: {},
    });

    const userId = auth?.user?.uid;

    let therapyScheduleTemplateId;
    let days;
    let dayOfWeek;
    let partOfDay;

    if (matchPartOfDay) {
        therapyScheduleTemplateId = matchPartOfDay.params.therapyScheduleTemplateId;
        days = matchPartOfDay.params.daysId;
        dayOfWeek = matchPartOfDay.params.dayOfWeek;
        partOfDay = matchPartOfDay.params.partOfDay;
    } else if (matchDayOfWeek) {
        therapyScheduleTemplateId = matchDayOfWeek.params.therapyScheduleTemplateId;
        days = matchDayOfWeek.params.daysId;
        dayOfWeek = matchDayOfWeek.params.dayOfWeek;
    } else if (matchDays) {
        therapyScheduleTemplateId = matchDays.params.therapyScheduleTemplateId;
        days = matchDays.params.daysId;
    } else if (matchId) {
        therapyScheduleTemplateId = matchId.params.therapyScheduleTemplateId;
    }

    /*
        The user object should look like this:

        {
            "firstName": "Eran",
            "uid": "zZ5GuwpCuzXIl4Ub06C3RAji1Pl1",
            "email": "eranc6@gmail.com",
            "lastName": "Chesner",
            "therapistScheduleTemplates": [
                {
                "therapyScheduleTemplate_link": "662a244060a5d77b11534897",
                "name": "Test Schedule 1"
                }
            ]
        }
    */

    useEffect(() => {
        const fetchAll = async (uid, templateId) => {
            const _user = await readDocument("users", uid);
            const _template = await readDocument("therapyScheduleTemplate", templateId);

            if (_template) {
                dispatch({
                    type: "set",
                    payload: {
                        template: _template,
                    },
                });
            }

            if (_user) {
                if (
                    _user.therapistScheduleTemplates &&
                    _user.therapistScheduleTemplates.length > 0
                ) {
                    let _userTemplate = _user.therapistScheduleTemplates.find(
                        (item) => item.therapyScheduleTemplate_link === templateId
                    );

                    if (_userTemplate) {
                        dispatch({
                            type: "set",
                            payload: {
                                userTemplate: _userTemplate,
                            },
                        });
                    } else {
                        // if the user does not have the template, add it
                        _userTemplate = {
                            therapyScheduleTemplate_link: templateId,
                            name: _template?.name,
                            startDate: _template?.startDate,
                            endDate: _template?.endDate,
                            therapistRequestedSchedule: getTherapistRequestedSchedule(),
                        };

                        await updateDocument("users", uid, {
                            ..._user,
                            therapistScheduleTemplates: [
                                ..._user.therapistScheduleTemplates,
                                _userTemplate,
                            ],
                        });

                        dispatch({
                            type: "set",
                            payload: {
                                userTemplate: _userTemplate,
                            },
                        });

                        _user.therapistScheduleTemplates.push(_userTemplate);
                    }
                } else {
                    // if the user does not have any templates, add this one
                    const _userTemplate = {
                        therapyScheduleTemplate_link: templateId,
                        name: _template?.name,
                        startDate: _template?.startDate,
                        endDate: _template?.endDate,
                        therapistRequestedSchedule: getTherapistRequestedSchedule(),
                    };

                    await updateDocument("users", uid, {
                        ..._user,
                        therapistScheduleTemplates: [_userTemplate],
                    });

                    dispatch({
                        type: "set",
                        payload: {
                            userTemplate: _userTemplate,
                        },
                    });

                    _user.therapistScheduleTemplates = [_userTemplate];
                }

                dispatch({
                    type: "set",
                    payload: {
                        user: _user,
                    },
                });
            } else {
                // if the user does not exist, create it
                const _user = {
                    uid,
                    therapistScheduleTemplates: [
                        {
                            therapyScheduleTemplate_link: templateId,
                            name: _template?.name,
                            startDate: _template?.startDate,
                            endDate: _template?.endDate,
                            therapistRequestedSchedule: getTherapistRequestedSchedule(),
                        },
                    ],
                };

                await createDocumentWithId("users", uid, _user);

                dispatch({
                    type: "set",
                    payload: {
                        user: _user,
                        userTemplate: _user.therapistScheduleTemplates[0],
                    },
                });
            }
        };

        if (therapyScheduleTemplateId && userId) {
            fetchAll(userId, therapyScheduleTemplateId);
        }
    }, [therapyScheduleTemplateId, userId]);

    const therapistRequestedSchedule =
        state.userTemplate &&
        state.userTemplate.therapistRequestedSchedule &&
        state.userTemplate.therapistRequestedSchedule.length > 0
            ? state.userTemplate.therapistRequestedSchedule
            : [];

    let renderType = "template";
    if (therapyScheduleTemplateId) {
        if (days) {
            renderType = "days";
            if (dayOfWeek) {
                renderType = "dayOfWeek";
                if (partOfDay) {
                    renderType = "partOfDay";
                }
            }
        }
    }

    //console.log("renderType", renderType, dayOfWeek);
    const closed = state.template.inactive || state.template.status === "Closed";

    const request = therapistRequestedSchedule.find(
        (r) => r.dayOfWeek === dayOfWeek && r.partOfDay === partOfDay
    );

    let therapistRequestedScheduleShifts = [];

    if (renderType === "partOfDay") {
        const templateItems = [];

        if (
            state.template.therapyScheduleTemplateItem &&
            state.template.therapyScheduleTemplateItem.length > 0
        ) {
            state.template.therapyScheduleTemplateItem.forEach((item) => {
                if (item.dayOfWeek !== dayOfWeek) {
                    return;
                }

                const _partOfDay = getPartOfDay(item.startTime, item.endTime);

                if (_partOfDay !== partOfDay) {
                    return;
                }

                templateItems.push(item);
            });
        }

        // merge templateItems with therapistRequestedScheduleShifts
        templateItems.forEach((item) => {
            if (
                request &&
                request.therapistRequestedScheduleShift &&
                request.therapistRequestedScheduleShift.length > 0
            ) {
                const shift = request.therapistRequestedScheduleShift.find(
                    (s) =>
                        s.startTime === item.startTime &&
                        s.endTime === item.endTime &&
                        s.therapyRoomId === item.therapyRoom._id
                );

                if (shift) {
                    item.enabled = shift.enabled;
                    item.slots = shift.slots;
                    item.priority = shift.priority;
                    item.therapyRoomId = shift.therapyRoomId;
                    item.therapyScheduleTemplateItemId =
                        shift.therapyScheduleTemplateItemId;
                }
            }
        });

        therapistRequestedScheduleShifts = templateItems.sort((a, b) => {
            return a.startTime.localeCompare(b.startTime);
        });
    }

    let title = state.template.name;
    if (dayOfWeek) {
        title = dayOfWeek;

        if (partOfDay) {
            title = dayOfWeek + " - " + partOfDay;
        }
    }

    return (
        <Page>
            <HomeNavbar title={title + (closed ? " (Closed)" : " (Open)")} />

            {/*<BlockTitle>{state.template.name}</BlockTitle>*/}

            {renderType === "template" ? (
                <TemplateView
                    state={state}
                    dispatch={dispatch}
                    userTemplate={state.userTemplate}
                    therapyScheduleTemplateId={therapyScheduleTemplateId}
                    templateUrlNumber="2"
                />
            ) : null}

            {renderType === "days" ? (
                <Block>
                    <List strong outlineIos insetIos={false}>
                        {DAYS_OF_WEEK.map((day, dayIndex) => {
                            const available = therapistRequestedSchedule.some(
                                (r) => r.dayOfWeek === day && r.availability === "Available"
                            );

                            const morning = therapistRequestedSchedule.some(
                                (r) =>
                                    r.dayOfWeek === day &&
                                    r.partOfDay === "Morning" &&
                                    r.availability === "Available"
                            );

                            const afternoon = therapistRequestedSchedule.some(
                                (r) =>
                                    r.dayOfWeek === day &&
                                    r.partOfDay === "Afternoon" &&
                                    r.availability === "Available"
                            );

                            const evening = therapistRequestedSchedule.some(
                                (r) =>
                                    r.dayOfWeek === day &&
                                    r.partOfDay === "Evening" &&
                                    r.availability === "Available"
                            );

                            return (
                                <div
                                    key={day}
                                    className={`h-[96px] flex justify-start items-center ${
                                        available
                                            ? dayIndex % 2 === 0
                                                ? "bg-primary/15 dark:bg-primary/25"
                                                : "bg-primary/25 dark:bg-primary/35"
                                            : ""
                                    } `}
                                >
                                    <div className="p-4 mr-4">
                                        <Checkbox
                                            component="div"
                                            name={day.toLowerCase() + "Enabled"}
                                            checked={available}
                                            onClick={(e) => {
                                                if (!closed) {
                                                    console.log(
                                                        "toggle_user_requests",
                                                        day
                                                    );
                                                    dispatch({
                                                        type: "toggle_user_requests",
                                                        payload: {
                                                            dayOfWeek: day,
                                                        },
                                                    });
                                                }
                                            }}
                                            disabled={closed}
                                        />
                                    </div>

                                    <div
                                        className={`grow py-2 h-full flex flex-col justify-between ${
                                            day !== "Sunday"
                                                ? "border-b border-gray-300"
                                                : ""
                                        }`}
                                    >
                                        <div className="flex justify-between items-center space-x-4">
                                            <div className="w-[102px] grow-0 shrink-0 text-lg font-bold">
                                                {day}
                                            </div>

                                            {available ? (
                                                <div className="flex items-center space-x-2 text-sm">
                                                    <div
                                                        className={`${
                                                            morning
                                                                ? "font-bold text-gray-700 dark:text-gray-200"
                                                                : "text-gray-400 dark:text-gray-600"
                                                        }`}
                                                    >
                                                        AM
                                                    </div>

                                                    <div
                                                        className={`${
                                                            afternoon
                                                                ? "font-bold text-gray-700 dark:text-gray-200"
                                                                : "text-gray-400 dark:text-gray-600"
                                                        }`}
                                                    >
                                                        AFT
                                                    </div>

                                                    <div
                                                        className={`${
                                                            evening
                                                                ? "font-bold text-gray-700 dark:text-gray-200"
                                                                : "text-gray-400 dark:text-gray-600"
                                                        }`}
                                                    >
                                                        PM
                                                    </div>
                                                </div>
                                            ) : null}

                                            {available ? (
                                                <div>
                                                    <Button
                                                        clear
                                                        className=""
                                                        onClick={() =>
                                                            navigate(
                                                                `/therapist-schedule-template2/${therapyScheduleTemplateId}/days/${day}`
                                                            )
                                                        }
                                                    >
                                                        <span>
                                                            {closed ? "View" : "Edit"}
                                                        </span>
                                                        <ChevronRightIcon className="size-6" />
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="mr-4 flex justify-between items-center">
                                            {available ? (
                                                <div className="h-10 flex justify-center items-center">
                                                    <SelectPriorityButtons
                                                        value={
                                                            state.userTemplate?.[
                                                                day.toLowerCase() +
                                                                    "Priority"
                                                            ] || ""
                                                        }
                                                        onChange={(value) => {
                                                            if (!closed) {
                                                                dispatch({
                                                                    type: "update_user_template",
                                                                    payload: {
                                                                        [day.toLowerCase() +
                                                                        "Priority"]: value,
                                                                    },
                                                                });
                                                            }
                                                        }}
                                                        disabled={!available}
                                                    />
                                                </div>
                                            ) : null}

                                            {available ? (
                                                <div>
                                                    <DoubleShiftsButton
                                                        checked={
                                                            state.userTemplate?.[
                                                                day.toLowerCase() +
                                                                    "MultipleShifts"
                                                            ] || false
                                                        }
                                                        onChange={(newValue) => {
                                                            if (!closed) {
                                                                dispatch({
                                                                    type: "update_user_template",
                                                                    payload: {
                                                                        [day.toLowerCase() +
                                                                        "MultipleShifts"]:
                                                                            newValue,
                                                                    },
                                                                });
                                                            }
                                                        }}
                                                        disabled={!available}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </List>
                </Block>
            ) : null}

            {renderType === "dayOfWeek" ? (
                <Block>
                    {/*<SelectPriority
                        title={dayOfWeek + " Priority"}
                        name={dayOfWeek.toLowerCase() + "Priority"}
                        value={
                            state.userTemplate?.[dayOfWeek.toLowerCase() + "Priority"] || ""
                        }
                        onChange={(name, value) => {
                            dispatch({
                                type: "update_user_template",
                                payload: {
                                    [name]: value,
                                },
                            });
                        }}
                    />*/}

                    <List strong outlineIos inset>
                        <ListItem
                            dividers={false}
                            title={
                                <div>
                                    {dayOfWeek} Priority:{" "}
                                    <span className="font-bold">
                                        {state.userTemplate?.[
                                            dayOfWeek.toLowerCase() + "Priority"
                                        ] || "Medium"}
                                    </span>
                                </div>
                            }
                        />

                        <ListInput
                            outline
                            label="Treatments/Day"
                            type="select"
                            dropdown
                            defaultValue=""
                            placeholder="Please choose..."
                            value={
                                state.userTemplate?.[
                                    dayOfWeek.toLowerCase() + "SlotsPerDay"
                                ] || ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;

                                dispatch({
                                    type: "update_user_template",
                                    payload: {
                                        [dayOfWeek.toLowerCase() + "SlotsPerDay"]: value,
                                    },
                                });
                            }}
                            disabled={closed}
                        >
                            <option value="" disabled>
                                Please choose...
                            </option>
                            {/* 0 to 5 */}
                            {Array.from(Array(6).keys()).map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </ListInput>

                        <ListItem
                            label
                            title="Double Shifts"
                            media={
                                <Checkbox
                                    component="div"
                                    name={dayOfWeek.toLowerCase() + "MultipleShifts"}
                                    checked={
                                        state.userTemplate?.[
                                            dayOfWeek.toLowerCase() + "MultipleShifts"
                                        ] || false
                                    }
                                    onChange={(e) => {
                                        const value = e.target.checked;

                                        dispatch({
                                            type: "update_user_template",
                                            payload: {
                                                [dayOfWeek.toLowerCase() +
                                                "MultipleShifts"]: value,
                                            },
                                        });
                                    }}
                                    disabled={closed}
                                />
                            }
                        />
                    </List>

                    <BlockTitle>{dayOfWeek}</BlockTitle>

                    <List strong outlineIos inset>
                        {PARTS_OF_DAY.map((part) => {
                            const _request = therapistRequestedSchedule.find(
                                (r) => r.dayOfWeek === dayOfWeek && r.partOfDay === part
                            );

                            let available = false;
                            let numberOfItems = 0;

                            if (_request) {
                                available = _request?.availability === "Available";

                                if (_request.therapistRequestedScheduleShift) {
                                    numberOfItems =
                                        _request.therapistRequestedScheduleShift.length;
                                }
                            }

                            return (
                                <ListItem
                                    key={part}
                                    label
                                    header={undefined}
                                    footer={""}
                                    className={`${
                                        available
                                            ? part === "Afternoon"
                                                ? "bg-primary/25 dark:bg-primary/35"
                                                : "bg-primary/15 dark:bg-primary/25"
                                            : ""
                                    } `}
                                    title={
                                        <div className="flex items-center space-x-2">
                                            {/*<div>
                                                {part === "Morning" ? (
                                                    <MorningIcon className="size-6" />
                                                ) : part === "Afternoon" ? (
                                                    <AfternoonIcon className="size-6" />
                                                ) : part === "Evening" ? (
                                                    <EveningIcon className="size-6" />
                                                ) : null}
                                            </div>*/}
                                            <div>
                                                {part} (
                                                {part === "Morning"
                                                    ? "AM"
                                                    : part === "Afternoon"
                                                    ? "AFT"
                                                    : "PM"}
                                                )
                                            </div>
                                        </div>
                                    }
                                    media={
                                        <Checkbox
                                            component="div"
                                            name={
                                                dayOfWeek.toLowerCase() + part + "Enabled"
                                            }
                                            checked={available}
                                            onChange={(e) => {
                                                dispatch({
                                                    type: "update_user_request",
                                                    payload: {
                                                        dayOfWeek,
                                                        partOfDay: part,
                                                        payload: {
                                                            availability: available
                                                                ? "Not Available"
                                                                : "Available",
                                                        },
                                                    },
                                                });
                                            }}
                                            disabled={closed}
                                        />
                                    }
                                    text={
                                        <div className="mt-2 h-10 flex justify-center items-center">
                                            {available ? (
                                                <SelectPriorityButtons
                                                    value={_request?.priority || "Medium"}
                                                    onChange={(value) => {
                                                        if (!closed) {
                                                            dispatch({
                                                                type: "update_user_request",
                                                                payload: {
                                                                    dayOfWeek,
                                                                    partOfDay: part,
                                                                    payload: {
                                                                        priority: value,
                                                                    },
                                                                },
                                                            });
                                                        }
                                                    }}
                                                    disabled={!available}
                                                />
                                            ) : null}
                                        </div>
                                    }
                                    subtitle={
                                        available
                                            ? numberOfItems > 0
                                                ? `selected ${numberOfItems} shifts`
                                                : "Any shift"
                                            : ""
                                    }
                                    after={
                                        available ? (
                                            <Button
                                                clear
                                                className="ml-4"
                                                onClick={() =>
                                                    navigate(
                                                        `/therapist-schedule-template2/${therapyScheduleTemplateId}/days/${dayOfWeek}/${part}`
                                                    )
                                                }
                                            >
                                                <span>{closed ? "View" : "Edit"}</span>
                                                <ChevronRightIcon className="size-6" />
                                            </Button>
                                        ) : null
                                    }
                                />
                            );
                        })}
                    </List>
                </Block>
            ) : null}

            {renderType === "partOfDay" ? (
                <Block>
                    {/*<BlockTitle>
                        {dayOfWeek} - {partOfDay}
                    </BlockTitle>

                    <List strong outlineIos inset>
                        <ListItem
                            label
                            title="Available"
                            media={
                                <Radio
                                    component="div"
                                    value="Available"
                                    checked={request?.availability === "Available"}
                                    onChange={() => {
                                        dispatch({
                                            type: "update_user_request",
                                            payload: {
                                                dayOfWeek,
                                                partOfDay,
                                                payload: {
                                                    availability: "Available",
                                                },
                                            },
                                        });
                                    }}
                                />
                            }
                        />

                        <ListItem
                            label
                            title="Unavailable"
                            media={
                                <Radio
                                    component="div"
                                    value="Not Available"
                                    checked={request?.availability !== "Available"}
                                    onChange={() => {
                                        dispatch({
                                            type: "update_user_request",
                                            payload: {
                                                dayOfWeek,
                                                partOfDay,
                                                payload: {
                                                    availability: "Not Available",
                                                },
                                            },
                                        });
                                    }}
                                />
                            }
                        />
                    </List>*/}

                    {/*request?.availability === "Available" ? (
                        <SelectPriority
                            title={dayOfWeek + " " + partOfDay + " Priority"}
                            name={"priority"}
                            value={request?.priority || "Medium"}
                            onChange={(name, value) => {
                                console.log(
                                    "update_user_request",
                                    dayOfWeek,
                                    partOfDay,
                                    value
                                );
                                dispatch({
                                    type: "update_user_request",
                                    payload: {
                                        dayOfWeek,
                                        partOfDay,
                                        payload: {
                                            priority: value,
                                        },
                                    },
                                });
                            }}
                        />
                    ) : null*/}

                    <List strong outlineIos inset>
                        <ListItem
                            title={
                                <div>
                                    {dayOfWeek} - {partOfDay} Priority:{" "}
                                    <span className="font-bold">
                                        {request?.priority || "Medium"}
                                    </span>
                                </div>
                            }
                        />
                    </List>

                    {request?.availability === "Available" ? (
                        <BlockTitle>Select Specific Shifts</BlockTitle>
                    ) : null}

                    {request?.availability === "Available" ? (
                        <List strong outlineIos inset>
                            {therapistRequestedScheduleShifts.map((shift) => (
                                <ListItem
                                    label
                                    key={
                                        shift.therapyRoom?.name +
                                        " " +
                                        shift.startTime +
                                        " - " +
                                        shift.endTime
                                    }
                                    after={shift.startTime + " - " + shift.endTime}
                                    title={shift.therapyRoom?.name}
                                    className={`${
                                        shift.enabled
                                            ? "bg-primary/15 dark:bg-primary/25"
                                            : ""
                                    } `}
                                    footer={
                                        <>
                                            {shift.therapyRoom?.facial ? (
                                                <Badge colors={{ bg: "bg-primary" }}>
                                                    Facial
                                                </Badge>
                                            ) : null}
                                            {shift.therapyRoom?.stones ? (
                                                <Badge colors={{ bg: "bg-primary" }}>
                                                    Stones
                                                </Badge>
                                            ) : null}
                                        </>
                                    }
                                    text={
                                        <div className="mt-2 flex justify-between items-center">
                                            <div>{shift.slots + " slots"}</div>
                                            <div>
                                                {shift.enabled ? (
                                                    <SelectPriorityButtons
                                                        value={shift.priority}
                                                        onChange={(value) => {
                                                            if (!closed) {
                                                                dispatch({
                                                                    type: "update_user_shift",
                                                                    payload: {
                                                                        dayOfWeek,
                                                                        partOfDay,
                                                                        startTime:
                                                                            shift.startTime,
                                                                        endTime:
                                                                            shift.endTime,
                                                                        slots: shift.slots,
                                                                        priority: value,
                                                                        therapyRoomId:
                                                                            shift
                                                                                .therapyRoom
                                                                                ._id,
                                                                        therapyScheduleTemplateItemId:
                                                                            shift._id,
                                                                        enabled:
                                                                            shift.enabled,
                                                                    },
                                                                });
                                                            }
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    }
                                    media={
                                        <Checkbox
                                            component="div"
                                            name={
                                                shift.therapyRoom?.name +
                                                " " +
                                                shift.startTime +
                                                " - " +
                                                shift.endTime
                                            }
                                            checked={shift.enabled}
                                            onChange={(e) => {
                                                const value = e.target.checked;

                                                dispatch({
                                                    type: "update_user_shift",
                                                    payload: {
                                                        dayOfWeek,
                                                        partOfDay,
                                                        startTime: shift.startTime,
                                                        endTime: shift.endTime,
                                                        slots: shift.slots,
                                                        priority: "Medium",
                                                        therapyRoomId:
                                                            shift.therapyRoom._id,
                                                        therapyScheduleTemplateItemId:
                                                            shift._id,
                                                        enabled: value,
                                                    },
                                                });
                                            }}
                                            disabled={closed}
                                        />
                                    }
                                />
                            ))}
                        </List>
                    ) : null}
                </Block>
            ) : null}

            {/*<Block>
                <div className="mt-8">
                    <pre>{JSON.stringify(state.user, null, 2)}</pre>
                </div>

                <div className="mt-8">
                    <pre>{JSON.stringify(state.template, null, 2)}</pre>
                </div>
            </Block>*/}
        </Page>
    );
};

export default TherapistScheduleTemplate2;
