import { useState, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { IoTodayOutline } from "react-icons/io5";

import { Page, Block, BlockTitle, List, ListItem, Badge } from "konsta/react";

import HomeNavbar from "../home/HomeNavbar";

import { readDocuments } from "lib/db";

const COLLECTION_NAME = "therapyScheduleTemplate";

const DAYS_OF_WEEK = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

const Template = () => {
    const navigate = useNavigate();
    const matchId = useMatch({ path: "/template/:day" });
    const [templates, setTemplates] = useState([]);

    const selectedDay = matchId ? matchId.params.day : "";

    useEffect(() => {
        const fetchTemplates = async () => {
            const templates = await readDocuments(COLLECTION_NAME);
            setTemplates(templates);
        };

        fetchTemplates();
    }, []);

    const template = templates && templates.length > 0 ? templates[0] : {};

    const items = {};

    if (
        template.therapyScheduleTemplateItem &&
        template.therapyScheduleTemplateItem.length > 0
    ) {
        template.therapyScheduleTemplateItem.forEach((item) => {
            if (!items[item.dayOfWeek]) {
                items[item.dayOfWeek] = [];
            }

            items[item.dayOfWeek].push(item);
        });
    }

    return (
        <Page>
            <HomeNavbar title={template.name} />

            <BlockTitle>Template: {template.name}</BlockTitle>

            <Block strong outlineIos inset className="flex justify-between">
                <p>
                    From {template.startDate} to {template.endDate}{" "}
                </p>
                <Badge colors={{ bg: template.inactive ? "bg-gray-500" : "bg-primary" }}>
                    {template.inactive ? "Inactive" : "Active"}
                </Badge>
            </Block>

            {!selectedDay ? (
                <Block>
                    <List strong outlineIos inset>
                        {DAYS_OF_WEEK.map((day) => (
                            <ListItem
                                key={day}
                                media={<IoTodayOutline className="size-6" />}
                                link
                                header={undefined}
                                footer={(items[day] ? items[day].length : 0) + " shifts"}
                                title={day}
                                after="Edit"
                                onClick={() => navigate(`/template/${day}`)}
                            />
                        ))}
                    </List>
                </Block>
            ) : null}

            {selectedDay ? (
                <Block>
                    <BlockTitle>{selectedDay}</BlockTitle>

                    <List strong outlineIos inset>
                        {items[selectedDay].map((item) => (
                            <ListItem
                                key={item.id}
                                header={item.startTime + " - " + item.endTime}
                                title={item.therapyRoom?.name}
                                footer={item.slots + " slots"}
                                after={
                                    <>
                                        {item.therapyRoom?.facial ? (
                                            <Badge colors={{ bg: "bg-primary" }}>
                                                Facial
                                            </Badge>
                                        ) : null}
                                        {item.therapyRoom?.stones ? (
                                            <Badge colors={{ bg: "bg-primary" }}>
                                                Stones
                                            </Badge>
                                        ) : null}
                                    </>
                                }
                            />
                        ))}
                    </List>
                </Block>
            ) : null}

            {/*<Block>
                <div className="mt-8">
                    <pre>{JSON.stringify(templates, null, 2)}</pre>
                </div>
            </Block>*/}
        </Page>
    );
};

export default Template;
