import PropTypes from "prop-types";

import {
    MdOutlineThumbDown as LowIcon,
    MdOutlineThumbUp as HighIcon,
} from "react-icons/md";

import { LuDot as MediumIcon } from "react-icons/lu";

import { BlockTitle, List, ListItem, Radio } from "konsta/react";

const SelectPriority = ({ title, name, value, onChange }) => {
    return (
        <>
            <BlockTitle>{title}</BlockTitle>
            <List strong outlineIos inset>
                <ListItem
                    label
                    title="High"
                    media={
                        <HighIcon className="p-1 size-7 text-gray-100 bg-green-500 dark:bg-green-700 rounded-full" />
                    }
                    after={
                        <Radio
                            component="div"
                            value="High"
                            checked={value === "High"}
                            onChange={() => onChange(name, "High")}
                        />
                    }
                />

                <ListItem
                    label
                    title="Medium"
                    media={
                        <MediumIcon className="p-1 size-7 text-gray-100 bg-yellow-500 dark:bg-yellow-600 rounded-full" />
                    }
                    after={
                        <Radio
                            component="div"
                            value="Medium"
                            checked={value === "Medium"}
                            onChange={() => onChange(name, "Medium")}
                        />
                    }
                />

                <ListItem
                    label
                    title="Low"
                    media={
                        <LowIcon className="p-1 size-7 text-gray-100 bg-red-500 dark:bg-red-600 rounded-full" />
                    }
                    after={
                        <Radio
                            component="div"
                            value="Low"
                            checked={value === "Low"}
                            onChange={() => onChange(name, "Low")}
                        />
                    }
                />
            </List>
        </>
    );
};

SelectPriority.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default SelectPriority;
