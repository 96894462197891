import moment from "moment";

export const formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
};

function sortObject(obj) {
    if (Array.isArray(obj)) {
        // If it's an array, recursively sort and filter undefined elements
        return obj
            .map((item) => sortObject(item))
            .filter((item) => item !== undefined)
            .sort();
    } else if (typeof obj === "object" && obj !== null) {
        // If it's an object, sort its keys
        const sortedKeys = Object.keys(obj).sort();
        const newObj = {};

        for (const key of sortedKeys) {
            const value = sortObject(obj[key]); // Recursively sort nested objects/arrays
            if (value !== undefined) {
                newObj[key] = value; // Only add if value is not undefined
            }
        }
        return newObj;
    }
    // If it's not an object/array and not undefined, return the value
    return obj === undefined ? undefined : obj;
}

export const calculateHash = async (jsonObj) => {
    // Step 1: Serialize the JSON object to a string
    const jsonString = JSON.stringify(sortObject(jsonObj));

    // Step 2: Convert the string to a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(jsonString);

    // Step 3: Use the SubtleCrypto API to hash the data (SHA-256)
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);

    // Step 4: Convert the ArrayBuffer to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");

    return hashHex;
};
