import PropTypes from "prop-types";

import { Button } from "konsta/react";

const DoubleShiftsButton = ({ checked, onChange, disabled = false }) => {
    return (
        <Button
            outline
            onClick={() => onChange(!checked)}
            className={`flex items-center k-color-primary ${
                checked && !disabled
                    ? "material:bg-primary material:text-white ios:bg-primary ios:text-white"
                    : ""
            }`}
            disabled={disabled}
        >
            <div className="flex justify-center">DBL SHIFTS</div>
        </Button>
    );
};

DoubleShiftsButton.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default DoubleShiftsButton;
