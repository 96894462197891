import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import cookie from "js-cookie";

import {
    Navbar,
    NavbarBackLink,
    Link,
    Icon,
    Panel,
    //Block,
    BlockTitle,
    Button,
    Page,
    List,
    ListItem,
    Radio,
    Toggle,
    Sheet,
    Toolbar,
    Block,
} from "konsta/react";

import { MdPerson } from "react-icons/md";

import { IoPersonCircle } from "react-icons/io5";

import { useAuth } from "../../lib/authUtils";
import ThemeContext from "../utils/ThemeContext";

const HomeNavbar = ({ title }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { theme, setTheme, colorTheme, setColorTheme, darkMode, setDarkMode } =
        useContext(ThemeContext);
    const auth = useAuth();
    const [openPanel, setOpenPanel] = useState(false);
    const [colorPickerOpened, setColorPickerOpened] = useState(false);
    const [openClearCookies, setOpenClearCookies] = useState(false);

    const userIsSignedIn = auth.user !== null;

    const handleSignOut = () => {
        auth.signOut();
        navigate("/");
    };

    const handleClearCookies = () => {
        // get all cookies names
        const _cookies = document.cookie.split(";");
        // iterate over all cookies
        for (let i = 0; i < _cookies.length; i++) {
            const _cookie = _cookies[i];

            const cookieName = _cookie.split("=")[0];

            console.log("cookieName", cookieName);

            // remove cookie
            cookie.remove(cookieName);

            // reload page
            window.location.reload();
        }
    };

    const handleTemplate = () => {
        navigate("/template");
    };

    return (
        <>
            <Navbar
                title={title}
                className="top-0 sticky"
                left={
                    location.pathname !== "/" && (
                        <NavbarBackLink onClick={() => navigate(-1)} />
                    )
                }
                right={
                    <Link navbar iconOnly onClick={() => setOpenPanel(true)}>
                        <Icon
                            ios={<IoPersonCircle className="w-7 h-7" />}
                            material={<MdPerson className="w-6 h-6" />}
                            badge={undefined}
                            badgeColors={{ bg: "bg-red-500" }}
                        />
                    </Link>
                }
            />

            <Panel
                side="right"
                opened={openPanel}
                onBackdropClick={() => setOpenPanel(false)}
            >
                <Page
                    colors={{
                        bgIos: "bg-ios-light-surface dark:bg-gray-600/50",
                        bgMaterial: "bg-md-light-surface dark:bg-gray-600/50",
                    }}
                >
                    <Navbar
                        title="User"
                        right={
                            <Link navbar onClick={() => setOpenPanel(false)}>
                                Close
                            </Link>
                        }
                    />

                    {!colorPickerOpened ? (
                        <>
                            <BlockTitle>Theme</BlockTitle>

                            <List strong inset>
                                <ListItem
                                    label
                                    title="iOS Theme"
                                    media={
                                        <Radio
                                            onChange={() => setTheme("ios")}
                                            component="div"
                                            checked={theme === "ios"}
                                        />
                                    }
                                />
                                <ListItem
                                    label
                                    title="Material Theme"
                                    media={
                                        <Radio
                                            onChange={() => setTheme("material")}
                                            component="div"
                                            checked={theme === "material"}
                                        />
                                    }
                                />
                            </List>

                            <List strong inset>
                                <ListItem
                                    title="Dark Mode"
                                    label
                                    after={
                                        <Toggle
                                            component="div"
                                            onChange={() => setDarkMode((x) => !x)}
                                            checked={darkMode}
                                        />
                                    }
                                />
                                <ListItem
                                    title="Color Theme"
                                    link
                                    onClick={() => setColorPickerOpened(true)}
                                    after={
                                        <div className="w-6 h-6 rounded-full bg-primary home-color-picker" />
                                    }
                                />
                            </List>

                            <BlockTitle>Actions</BlockTitle>

                            <List strong inset>
                                <ListItem
                                    link
                                    title="Open Home Page"
                                    onClick={() => navigate("/")}
                                />
                                {/*<ListItem link title="Template" onClick={handleTemplate} />
                                <ListItem
                                    link
                                    title="Therapist Schedule Template (OLD)"
                                    onClick={() => navigate("/therapist-schedule-template")}
                                />
                                <ListItem
                                    link
                                    title="Therapist Schedule Template 2"
                                    onClick={() =>
                                        navigate("/therapist-schedule-template2")
                                    }
                                />*/}
                            </List>

                            {userIsSignedIn ? (
                                <div className="mx-4">
                                    <Button
                                        onClick={() => {
                                            setOpenPanel(false);
                                            handleSignOut();
                                        }}
                                        raised
                                        large
                                        className="k-color-brand-red"
                                    >
                                        Sign Out
                                    </Button>
                                </div>
                            ) : null}

                            <div className="mt-8 mx-4">
                                <Button
                                    onClick={() => setOpenClearCookies(true)}
                                    className="k-color-brand-red"
                                    fill
                                    raised
                                    large
                                >
                                    Clear Cookies
                                </Button>
                            </div>
                        </>
                    ) : null}

                    {colorPickerOpened ? (
                        <>
                            <BlockTitle>Color Theme</BlockTitle>

                            <List strongIos outlineIos>
                                <ListItem
                                    label
                                    title="Default"
                                    media={
                                        <Radio
                                            component="div"
                                            value="Default"
                                            checked={colorTheme === ""}
                                            onChange={() => {
                                                setColorTheme("");
                                                setColorPickerOpened(false);
                                            }}
                                        />
                                    }
                                    after={
                                        <div className="w-6 h-6 rounded-full bg-primary" />
                                    }
                                />

                                <ListItem
                                    label
                                    title="Red"
                                    media={
                                        <Radio
                                            component="div"
                                            value="k-color-brand-red"
                                            checked={colorTheme === "k-color-brand-red"}
                                            onChange={() => {
                                                setColorTheme("k-color-brand-red");
                                                setColorPickerOpened(false);
                                            }}
                                        />
                                    }
                                    after={
                                        <div className="w-6 h-6 rounded-full bg-brand-red" />
                                    }
                                />

                                <ListItem
                                    label
                                    title="Green"
                                    media={
                                        <Radio
                                            component="div"
                                            value="k-color-brand-green"
                                            checked={colorTheme === "k-color-brand-green"}
                                            onChange={() => {
                                                setColorTheme("k-color-brand-green");
                                                setColorPickerOpened(false);
                                            }}
                                        />
                                    }
                                    after={
                                        <div className="w-6 h-6 rounded-full bg-brand-green" />
                                    }
                                />

                                <ListItem
                                    label
                                    title="Yellow"
                                    media={
                                        <Radio
                                            component="div"
                                            value="k-color-brand-yellow"
                                            checked={colorTheme === "k-color-brand-yellow"}
                                            onChange={() => {
                                                setColorTheme("k-color-brand-yellow");
                                                setColorPickerOpened(false);
                                            }}
                                        />
                                    }
                                    after={
                                        <div className="w-6 h-6 rounded-full bg-brand-yellow" />
                                    }
                                />

                                <ListItem
                                    label
                                    title="Purple"
                                    media={
                                        <Radio
                                            component="div"
                                            value="k-color-brand-purple"
                                            checked={colorTheme === "k-color-brand-purple"}
                                            onChange={() => {
                                                setColorTheme("k-color-brand-purple");
                                                setColorPickerOpened(false);
                                            }}
                                        />
                                    }
                                    after={
                                        <div className="w-6 h-6 rounded-full bg-brand-purple" />
                                    }
                                />
                            </List>

                            <div className="mx-4">
                                <Button
                                    onClick={() => setColorPickerOpened(false)}
                                    className="mt-4"
                                    fill
                                    raised
                                    large
                                >
                                    Cancel
                                </Button>
                            </div>
                        </>
                    ) : null}

                    <Sheet
                        className="w-full max-w-[calc(min(100%,460px))] ml-[calc(100%-min(100%,460px))]"
                        opened={openClearCookies}
                        onBackdropClick={() => setOpenClearCookies(false)}
                    >
                        <Toolbar top>
                            <div className="left" />
                            <div className="right">
                                <Link onClick={() => setOpenClearCookies(false)}>
                                    Cancel
                                </Link>
                            </div>
                        </Toolbar>
                        <Block>
                            <p>
                                Are you sure you want to clear all cookies? This will log
                                you out, and you will need to sign in again. Data is NOT
                                lost.
                            </p>
                            <div className="mt-4 mb-16">
                                <Button
                                    large
                                    className="k-color-brand-red"
                                    onClick={handleClearCookies}
                                >
                                    Clear Cookies
                                </Button>
                            </div>
                        </Block>
                    </Sheet>
                </Page>
            </Panel>
        </>
    );
};

HomeNavbar.propTypes = {
    title: PropTypes.string,
};

export default HomeNavbar;
