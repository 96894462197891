import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Page, BlockTitle, List, ListItem, Badge } from "konsta/react";

import HomeNavbar from "../home/HomeNavbar";

import { readDocuments } from "lib/db";

import { formatDate } from "../utils/utils";

const COLLECTION_NAME = "therapyScheduleTemplate";

const TherapistScheduleTemplates2 = () => {
    const navigate = useNavigate();
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        const fetchTemplates = async () => {
            const _templates = await readDocuments(COLLECTION_NAME);
            if (_templates && _templates.length > 0) {
                setTemplates(_templates);
            }
        };

        fetchTemplates();
    }, []);

    return (
        <div>
            <BlockTitle>Schedule Periods</BlockTitle>

            <List strong outlineIos inset>
                {templates.map((item) => (
                    <ListItem
                        link
                        key={item._id}
                        header={
                            formatDate(item.startDate) + " - " + formatDate(item.endDate)
                        }
                        title={item.name}
                        footer={""}
                        after={
                            <>
                                {item.inactive || item.status === "Closed" ? (
                                    <Badge colors={{ bg: "bg-gray-500" }}>Closed</Badge>
                                ) : (
                                    <Badge colors={{ bg: "bg-primary" }}>Open</Badge>
                                )}
                            </>
                        }
                        onClick={() =>
                            navigate(`/therapist-schedule-template2/${item._id}`)
                        }
                    />
                ))}
            </List>
        </div>
    );
};

export default TherapistScheduleTemplates2;
