import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { isAndroid } from "react-device-detect";
import { App as KonstaApp } from "konsta/react";

import { useLocalStorage } from "purpleowl-libs/utils/hooks";

import Home from "./components/home/Home";
import FinishSignIn from "./components/home/FinishSignIn";
import Template from "./components/schedule/Template";
import ThemeContext from "./components/utils/ThemeContext";
import TherapistScheduleTemplates from "./components/schedule/TherapistScheduleTemplates";
import TherapistScheduleTemplate from "./components/schedule/TherapistScheduleTemplate";
import TherapistScheduleTemplates2 from "./components/schedule/TherapistScheduleTemplates2";
import TherapistScheduleTemplate2 from "./components/schedule/TherapistScheduleTemplate2";
import ViewAndSubmit from "./components/schedule/ViewAndSubmit";
import ViewAssignedSchedule from "./components/schedule/ViewAssignedSchedule";

const TITLE = "ten thousand waves";

function App() {
    const [theme, setTheme] = useLocalStorage(
        "portal.theme",
        isAndroid ? "material" : "ios"
    );
    const [currentColorTheme, setCurrentColorTheme] = useLocalStorage(
        "portal.currentColorTheme",
        ""
    );
    const [darkMode, setDarkMode] = useLocalStorage("portal.darkMode", false);

    const setColorTheme = React.useCallback(
        (color) => {
            const htmlEl = document.documentElement;
            htmlEl.classList.forEach((c) => {
                if (c.includes("k-color")) htmlEl.classList.remove(c);
            });
            if (color) htmlEl.classList.add(color);
            setCurrentColorTheme(color);
        },
        [setCurrentColorTheme]
    );

    useEffect(() => {
        if (currentColorTheme) setColorTheme(currentColorTheme);
    }, [currentColorTheme, setColorTheme]);

    useEffect(() => {
        if (darkMode) {
            const _darkMode = document.documentElement.classList.contains("dark");
            if (!_darkMode) {
                document.documentElement.classList.add("dark");
            }
        } else {
            const _darkMode = document.documentElement.classList.contains("dark");
            if (_darkMode) {
                document.documentElement.classList.remove("dark");
            }
        }
    }, [darkMode]);

    const inIFrame = window.parent !== window;

    useLayoutEffect(() => {
        if (window.location.href.includes("safe-areas")) {
            const html = document.documentElement;
            if (html) {
                html.style.setProperty(
                    "--k-safe-area-top",
                    theme === "ios" ? "44px" : "24px"
                );
                html.style.setProperty("--k-safe-area-bottom", "34px");
            }
        }
    }, [theme]);

    return (
        <div className="bg-gray-800">
            <KonstaApp
                theme={theme}
                safeAreas={!inIFrame}
                className="max-w-[460px] ml-auto"
            >
                <BrowserRouter basename="/portal">
                    <ThemeContext.Provider
                        value={{
                            theme,
                            setTheme,
                            colorTheme: currentColorTheme,
                            setColorTheme,
                            darkMode,
                            setDarkMode,
                        }}
                    >
                        <Routes>
                            <Route path="/" element={<Home title={TITLE} />} />
                            <Route path="/finish-sign-in" element={<FinishSignIn />} />

                            <Route path="/template" element={<Template />} />
                            <Route path="/template/:shiftId" element={<Template />} />

                            <Route
                                path="/therapist-schedule-template"
                                element={<TherapistScheduleTemplates />}
                            />
                            <Route
                                path="/therapist-schedule-template/:therapyScheduleTemplateId"
                                element={<TherapistScheduleTemplate />}
                            />
                            <Route
                                path="/therapist-schedule-template/:therapyScheduleTemplateId/:daysId"
                                element={<TherapistScheduleTemplate />}
                            />
                            <Route
                                path="/therapist-schedule-template/:therapyScheduleTemplateId/:daysId/:dayOfWeek"
                                element={<TherapistScheduleTemplate />}
                            />
                            <Route
                                path="/therapist-schedule-template/:therapyScheduleTemplateId/:daysId/:dayOfWeek/:partPfDay"
                                element={<TherapistScheduleTemplate />}
                            />

                            <Route
                                path="/therapist-schedule-template2"
                                element={<TherapistScheduleTemplates2 />}
                            />
                            <Route
                                path="/therapist-schedule-template2/:therapyScheduleTemplateId"
                                element={<TherapistScheduleTemplate2 />}
                            />
                            <Route
                                path="/therapist-schedule-template2/:therapyScheduleTemplateId/:daysId"
                                element={<TherapistScheduleTemplate2 />}
                            />
                            <Route
                                path="/therapist-schedule-template2/:therapyScheduleTemplateId/:daysId/:dayOfWeek"
                                element={<TherapistScheduleTemplate2 />}
                            />
                            <Route
                                path="/therapist-schedule-template2/:therapyScheduleTemplateId/:daysId/:dayOfWeek/:partPfDay"
                                element={<TherapistScheduleTemplate2 />}
                            />

                            <Route
                                path="/therapist-schedule-view-and-submit/:therapyScheduleTemplateId"
                                element={<ViewAndSubmit />}
                            />

                            <Route
                                path="/therapist-schedule-view-assigned/:therapyScheduleTemplateId"
                                element={<ViewAssignedSchedule />}
                            />
                        </Routes>
                    </ThemeContext.Provider>
                </BrowserRouter>
            </KonstaApp>
        </div>
    );
}

export default App;
