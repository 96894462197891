import { initializeApp, getApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
//import "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";

// const config = {
//     apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
//     authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
//     messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
//     appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
//     measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
// };

// config for 10kw-test (eranc6)
const config2 = {
    apiKey: "AIzaSyApbHHiIfWXTWIWRoRikf-NNwDPRp1xiF4",
    authDomain: "kw-test-3dac9.firebaseapp.com",
    projectId: "kw-test-3dac9",
    storageBucket: "kw-test-3dac9.appspot.com",
    messagingSenderId: "718101221459",
    appId: "1:718101221459:web:e219642d98a4eceba836a6",
    measurementId: "G-568MDBHTV2",
};

// config for 10kw-prod (10 Thousand Wave Portal)
const config3 = {
    apiKey: "AIzaSyB0fYLy_4OzGbtmIOPNdSZtMnDotWWBdJo",
    authDomain: "tenthousandwaves.firebaseapp.com",
    projectId: "tenthousandwaves",
    storageBucket: "tenthousandwaves.appspot.com",
    messagingSenderId: "1065502366631",
    appId: "1:1065502366631:web:0693d369a35ee003797482",
    measurementId: "G-6DC3G9K93S",
};

let app;

if (!getApps().length) {
    app = initializeApp(config3);
} else {
    app = getApp();
}

const auth = getAuth(app);
const db = getFirestore();
//const analytics = getAnalytics(app);

export default { auth, db };
