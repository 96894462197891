import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { useTheme } from "pui/Theme";
import colors from "pui/colors";

const puiColors = {
    ...colors,
    white: { 500: "#ddd" },
    black: { 500: "#222" },
};

/**
 * like useState with local storage
 * From: https://usehooks.com/useLocalStorage/
 * @param {*} key
 * @param {*} initialValue
 */
export const useLocalStorage = (key, initialValue, enableLocalStorage = true) => {
    const keyRef = useRef(key);

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (enableLocalStorage) {
            try {
                // Get from local storage by key
                const item = window.localStorage.getItem(keyRef.current);

                // Parse stored json or if none return initialValue
                return item ? JSON.parse(item) : initialValue;
            } catch (error) {
                // If error also return initialValue
                console.log(error);
                return initialValue;
            }
        } else {
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;

            // Save state
            setStoredValue(valueToStore);

            // Save to local storage
            if (enableLocalStorage) {
                window.localStorage.setItem(keyRef.current, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    const updateKey = (newKey) => {
        keyRef.current = newKey;

        // Get from local storage by key
        const item = window.localStorage.getItem(keyRef.current);

        // Parse stored json or if none return initialValue
        const parsedItem = item ? JSON.parse(item) : initialValue;

        setStoredValue(parsedItem);
    };

    return [storedValue, setValue, updateKey];
};

/**
 * get the window size
 * From: https://usehooks.com/useWindowSize/
 */
export const useWindowSize = () => {
    const isClient = typeof window === "object";

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};

/**
 * Used internally by useUpdateEffect
 */
const useIsMounted = function useIsMounted() {
    const isMounted = React.useRef(false);

    React.useEffect(function setIsMounted() {
        isMounted.current = true;

        return function cleanupSetIsMounted() {
            isMounted.current = false;
        };
    }, []);

    return isMounted;
};

/**
 * Update only after the component is mounted
 * @param {*} effect
 * @param {*} dependencies
 */
export const useUpdateEffect = function useUpdateEffect(effect, dependencies) {
    const isMounted = useIsMounted();
    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        let effectCleanupFunc = function noop() {};

        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            effectCleanupFunc = effect() || effectCleanupFunc;
        }
        return () => {
            effectCleanupFunc();
            if (!isMounted.current) {
                isInitialMount.current = true;
            }
        };
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useLabelStyle = () => {
    const theme = useTheme();
    const { labelTextSize, labelContrast, labelColor, form } = useSelector(
        (state) => state.settings
    );

    let labelStyle = {};
    if (labelTextSize === "large") {
        labelStyle.fontSize = form.fontSize + 4;
    }

    if (labelColor === "default") {
        if (labelContrast === "high") {
            labelStyle.color = theme.type === "light" ? "#333" : "#d8d8d8";
        }
    } else {
        const shade = 500;
        labelStyle.color = puiColors[labelColor][shade];
    }

    return labelStyle;
};

// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/hooks/use-latest-value.ts
export function useLatestValue(value) {
    let cache = useRef(value);

    useEffect(() => {
        cache.current = value;
    }, [value]);

    return cache;
}

// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/hooks/use-event-listener.ts
export function useEventListener(element, type, listener, options) {
    let listenerRef = useLatestValue(listener);

    useEffect(() => {
        element = element ?? window;

        function handler(event) {
            listenerRef.current(event);
        }

        element.addEventListener(type, handler, options);
        return () => element.removeEventListener(type, handler, options);
    }, [element, type, options]);
}

export function useHover(enable) {
    const [value, setValue] = useState(false);

    const ref = useRef(null);
    // var to store timer id between hook calls
    const timerRef = useRef(null);

    useEffect(() => {
        const node = ref.current;
        if (node && enable) {
            const handleMouseOver = () => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }

                timerRef.current = setTimeout(() => {
                    setValue(true);
                }, 0);
            };
            const handleMouseOut = () => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }

                timerRef.current = setTimeout(() => {
                    setValue(false);
                }, 0);
            };

            node.addEventListener("mouseover", handleMouseOver);
            node.addEventListener("mouseout", handleMouseOut);

            return () => {
                node.removeEventListener("mouseover", handleMouseOver);
                node.removeEventListener("mouseout", handleMouseOut);
            };
        }
    }, [enable]);

    return [ref, value];
}

export function useForceUpdate() {
    const [, updateState] = React.useState();
    return React.useCallback(() => updateState({}), []);
}
