import { useContext } from "react";
import cookie from "js-cookie";
//import { useQuery } from "react-query";

import authContext from "./authContext";

export const COOKIE_NAME = "ten-thousand-waves-token";

export const useAuth = () => {
    return useContext(authContext);
};

export const fetcherWithAuth = async (url, token) => {
    const res = await fetch(url, {
        method: "GET",
        headers: { token },
    });

    return await res.json();
};

export const fetchWithAuth = async ({ queryKey }) => {
    const [url, token] = queryKey;

    const res = await fetch(url, {
        method: "GET",
        headers: { token },
    });

    return await res.json();
};

// export const useQueryWithToken = (url, token, initialData) => {
//     let { data, refetch } = useQuery([url, token], fetchWithAuth, {
//         initialData,
//         enabled: token !== "",
//     });

//     if (token === "" || data === undefined) {
//         data = initialData;
//     }

//     return { data, refetch };
// };

export const updateCookie = (token) => {
    if (token) {
        cookie.set(COOKIE_NAME, token, {
            expires: 1,
        });
    }
};
