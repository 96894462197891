import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMatch, useNavigate } from "react-router-dom";
import moment from "moment";

// change 24 hour time to 12 hour time
const formatTime = (time) => {
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ":" + minutes + " " + ampm;
};

import {
    Page,
    Card,
    Block,
    BlockTitle,
    List,
    ListItem,
    ListInput,
    Checkbox,
    Badge,
    Button,
    Sheet,
    Toolbar,
} from "konsta/react";

import HomeNavbar from "../home/HomeNavbar";
import { formatDate, calculateHash } from "../utils/utils";
import { readDocument } from "lib/db";
import { useAuth } from "lib/authUtils";

const ViewAssignedSchedule = () => {
    const auth = useAuth();
    const [assignedSchedule, setAssignedSchedule] = useState(null);
    const matchId = useMatch({
        path: "/therapist-schedule-view-assigned/:therapyScheduleTemplateId",
    });

    let therapyScheduleTemplateId;
    if (matchId) {
        therapyScheduleTemplateId = matchId.params.therapyScheduleTemplateId;
    }

    const userId = auth?.user?.uid;

    useEffect(() => {
        const readAssignedSchedule = async () => {
            const doc = await readDocument("assignedSchedule", userId);

            if (doc) {
                const _assignedSchedule = doc.therapistScheduleTemplates.find(
                    (item) => item.therapyScheduleTemplateId === therapyScheduleTemplateId
                );

                console.log("assingedSchedule", _assignedSchedule);

                if (_assignedSchedule) {
                    setAssignedSchedule(_assignedSchedule);
                } else {
                    setAssignedSchedule(null);
                }
            }
        };

        if (userId && therapyScheduleTemplateId) {
            readAssignedSchedule();
        }
    }, [userId, therapyScheduleTemplateId]);

    console.log("assignedSchedule", assignedSchedule, {
        userId,
        therapyScheduleTemplateId,
    });

    let therapist = assignedSchedule ? assignedSchedule.schedule : {};

    return (
        <Page>
            <HomeNavbar
                title={assignedSchedule ? assignedSchedule.therapyScheduleTemplateName : ""}
            />

            <BlockTitle>Assigned Schedule</BlockTitle>
            <Block>
                <p>
                    This is the assinged schedule for{" "}
                    {assignedSchedule ? assignedSchedule.therapyScheduleTemplateName : ""}
                </p>
            </Block>

            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
                .filter((day) => therapist[day] && therapist[day].shifts.length > 0)
                .map((day) => (
                    <Card header={day} key={therapist + "." + day}>
                        <List nested className="">
                            {therapist[day].shifts.map((shift, index) => (
                                <Item
                                    name={
                                        <div className="space-y-1">
                                            <div>
                                                {`${formatTime(
                                                    shift.startTime
                                                )} - ${formatTime(shift.endTime)}`}
                                            </div>
                                            <div>{`${shift.slots} treatments`}</div>
                                        </div>
                                    }
                                    value={`${shift.therapyRoom}`}
                                    key={therapist + "." + day + "." + index}
                                />
                            ))}
                        </List>
                    </Card>
                ))}
        </Page>
    );
};

const Item = ({ name, value }) => {
    return <ListItem title={name} after={value} />;
};

Item.propTypes = {
    name: PropTypes.string,
    value: PropTypes.node,
};

export default ViewAssignedSchedule;
