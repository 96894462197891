import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../lib/authUtils";

const FinishSignIn = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        const url = window.location.href;

        const user = auth.checkSignInWithEmailLink(url);

        console.log("FinishSignIn", user);

        if (user) {
            navigate("/");
        }
    }, [auth, navigate]);

    return (
        <div>
            <h2>FinishSignIn</h2>

            <div className="mt-8">
                <pre>{JSON.stringify(auth.user, null, 2)}</pre>
            </div>
        </div>
    );
};

export default FinishSignIn;
