import React, { useState, useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
import cookie from "js-cookie";

import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    sendEmailVerification,
    onIdTokenChanged,
    signOut,
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
} from "firebase/auth";

import firebase from "./firebase";
import authContext from "./authContext";
import { createUser, readDocument } from "./db";

import { COOKIE_NAME } from "./authUtils";

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleUser = React.useCallback(async (rawUser) => {
        if (rawUser) {
            const overwriteUid = localStorage.getItem("portal.uid");

            const token = await rawUser.getIdToken();
            const _user = {
                uid: overwriteUid ? overwriteUid : rawUser.uid,
                originalUid: rawUser.uid,
                email: rawUser.email,
                emailVerified: rawUser.emailVerified,
                name: rawUser.displayName,
                provider: rawUser.providerData[0].providerId,
                photoUrl: rawUser.photoURL,
                token,
            };

            const userDoc = await readDocument("users", _user.uid);
            if (userDoc && userDoc.email === _user.email) {
                _user.firstName = userDoc.firstName;
                _user.lastName = userDoc.lastName;
            }

            setUser(_user);

            cookie.set(COOKIE_NAME, _user.token, {
                expires: 1,
            });

            setLoading(false);
            return _user;
        } else {
            setUser(null);
            cookie.remove(COOKIE_NAME);

            setLoading(false);
            return null;
        }
    }, []);

    const sendEmailLink = async (email) => {
        localStorage.setItem("emailForSignIn", email);

        await sendSignInLinkToEmail(firebase.auth, email, {
            // URL to redirect back to
            url: window.location.origin + "/portal/finish-sign-in",
            handleCodeInApp: true,
        });

        return true;
    };

    const checkSignInWithEmailLink = async (url) => {
        const email = localStorage.getItem("emailForSignIn");

        if (isSignInWithEmailLink(firebase.auth, url)) {
            let response = await signInWithEmailLink(firebase.auth, email, url);

            window.localStorage.removeItem("emailForSignIn");

            const _user = handleUser(response.user);

            return _user;
        } else {
            return null;
        }
    };

    const signInWithEmail = async (email, password) => {
        setLoading(true);

        const response = await signInWithEmailAndPassword(firebase.auth, email, password);

        console.log("signInWithEmail", response.user);

        return handleUser(response.user);
    };

    const resendEmail = async () => {
        /*if (rawUser) {
            await sendEmailVerification(rawUser);
        }*/

        console.log("resendEmail", firebase.auth.currentUser);

        await sendEmailVerification(firebase.auth.currentUser);
    };

    const signUp = async (email, password, name) => {
        const response = await createUserWithEmailAndPassword(
            firebase.auth,
            email,
            password
        );

        await sendEmailVerification(response.user);

        const user = await handleUser(response.user);
        if (name) {
            user.name = name;
        }

        // const { token, ...userWithoutToken } = user;
        //
        // try {
        //     await createUser(user.uid, { ...userWithoutToken, firstTime: true });
        // } catch (err) {
        //     console.log("createUser failed", err);
        // }

        return user;
    };

    const sendPasswordResetEmailToUser = async (email) => {
        await sendPasswordResetEmail(firebase.auth, email);

        return true;
    };

    // const confirmPasswordResetToUser = async (password, code) => {
    //     const resetCode = code || getFromQueryString("oobCode");

    //     await confirmPasswordReset(firebase.auth, resetCode, password);

    //     return true;
    // };

    // const signInWithGoogle = async (redirect) => {
    //     setLoading(true);

    //     const response = await signInWithPopup(
    //         firebase.auth,
    //         new auth.GoogleAuthProvider()
    //     );

    //     handleUser(response.user);

    //     if (redirect) {
    //         //Router.push(redirect);
    //     }
    // };

    const signOutFunc = async () => {
        await signOut(firebase.auth);

        // this will delete the cookie
        return await handleUser(null);
    };

    // Adds an observer for changes to the signed-in user's ID token, which includes sign-in, sign-out, and token refresh events.
    // This method has the same behavior as firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0.
    useEffect(() => {
        const unsubscribe = onIdTokenChanged(firebase.auth, handleUser);

        return () => unsubscribe();
    }, [handleUser]);

    return {
        user,
        loading,
        signInWithEmail,
        signUp,
        sendPasswordResetEmailToUser,
        // confirmPasswordResetToUser,
        // signInWithGoogle,
        signOut: signOutFunc,
        resendEmail,
        sendEmailLink,
        checkSignInWithEmailLink,
    };
}

const AuthProvider = ({ children }) => {
    const provideAuth = useProvideAuth();
    return <authContext.Provider value={provideAuth}>{children}</authContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export default AuthProvider;
