import React, { useState } from "react";

import { MdEmail } from "react-icons/md";

import { IoMail } from "react-icons/io5";

import { Block, BlockTitle, List, ListInput, Icon, Button } from "konsta/react";

import { useAuth } from "../../lib/authUtils";

const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

const SignIn = () => {
    const auth = useAuth();
    const [email, setEmail] = useState({ value: "", changed: false });
    const [emailSent, setEmailSent] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        await auth.sendEmailLink(email.value);

        setEmailSent(true);
    };

    return (
        <>
            <Block>
                <BlockTitle>Sign In</BlockTitle>
                <List strongIos insetIos>
                    <ListInput
                        outline
                        label="Email"
                        type="email"
                        placeholder="Your email"
                        value={email.value}
                        error={
                            email.changed && !validateEmail(email.value)
                                ? "Please enter a valid email"
                                : ""
                        }
                        media={
                            <Icon
                                ios={<IoMail className="w-7 h-7" />}
                                material={<MdEmail className="w-6 h-6" />}
                            />
                        }
                        onChange={(e) => setEmail({ value: e.target.value, changed: true })}
                    />
                </List>

                <div className="mx-4">
                    <Button
                        fill
                        large
                        onClick={handleSubmit}
                        disabled={!validateEmail(email.value) || emailSent}
                    >
                        Get Email with Sign In Link
                    </Button>
                </div>
            </Block>

            {emailSent ? (
                <Block strong>
                    <p>
                        An email with a sign in link has been sent to{" "}
                        <strong>{email.value}</strong>. Open the email and click the link to
                        sign in.
                    </p>
                </Block>
            ) : null}
        </>
    );
};

export default SignIn;
