import { useState, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import {
    Page,
    Block,
    BlockTitle,
    List,
    ListItem,
    Card,
    Badge,
    Button,
    Sheet,
    Toolbar,
    Link,
    Dialog,
    DialogButton,
} from "konsta/react";

import HomeNavbar from "../home/HomeNavbar";
import { formatDate, calculateHash } from "../utils/utils";

import { Timestamp } from "firebase/firestore";
import { readDocument, updateDocument, createDocumentWithId } from "lib/db";
import { useAuth } from "lib/authUtils";

const ViewAndSubmit = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const [user, setUser] = useState({});
    const [userTemplate, setUserTemplate] = useState({});
    const [template, setTemplate] = useState({});
    const [openSubmit, setOpenSubmit] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const matchId = useMatch({
        path: "/therapist-schedule-view-and-submit/:therapyScheduleTemplateId",
    });

    const userId = auth?.user?.uid;

    let therapyScheduleTemplateId;
    if (matchId) {
        therapyScheduleTemplateId = matchId.params.therapyScheduleTemplateId;
    }

    const closed = template?.inactive || template?.status === "Closed";

    useEffect(() => {
        const fetchAll = async (uid, templateId) => {
            const _user = await readDocument("users", uid);
            const _template = await readDocument("therapyScheduleTemplate", templateId);

            if (_template) {
                setTemplate(_template);
            }

            if (_user) {
                if (
                    _user.therapistScheduleTemplates &&
                    _user.therapistScheduleTemplates.length > 0
                ) {
                    let _userTemplate = _user.therapistScheduleTemplates.find(
                        (item) => item.therapyScheduleTemplate_link === templateId
                    );

                    if (_userTemplate) {
                        setUserTemplate(_userTemplate);
                    }
                }

                setUser(_user);
            }
        };

        if (therapyScheduleTemplateId && userId) {
            fetchAll(userId, therapyScheduleTemplateId);
        }
    }, [therapyScheduleTemplateId, userId]);

    if (!userTemplate) {
        return <Page />;
    }

    const days = getDays(userTemplate);

    console.log("days", days);
    console.log("Template", template);

    const therapyRoomMap = {};
    if (template.therapyScheduleTemplateItem) {
        template.therapyScheduleTemplateItem.forEach((item) => {
            if (item.therapyRoom) {
                therapyRoomMap[item.therapyRoom._id] = item.therapyRoom;
            }
        });
    }

    const submitSchedule = async () => {
        if (userId && therapyScheduleTemplateId) {
            const now = Timestamp.now();

            const id = userId + "." + therapyScheduleTemplateId;

            // check if the userSubmit document already exists
            const _userSubmit = await readDocument("userSubmit", id);

            if (_userSubmit) {
                await updateDocument("userSubmit", id, {
                    uid: userId,
                    therapyScheduleTemplateId,
                    dateTime: now,
                });
            } else {
                await createDocumentWithId("userSubmit", id, {
                    uid: userId,
                    therapyScheduleTemplateId,
                    dateTime: now,
                });
            }

            const _user = await readDocument("users", userId);

            const therapistScheduleTemplates = [];

            for (let i = 0; i < _user.therapistScheduleTemplates.length; i++) {
                const item = _user.therapistScheduleTemplates[i];

                if (item.therapyScheduleTemplate_link === therapyScheduleTemplateId) {
                    const hash = await calculateHash({
                        ...item,
                        submitted: undefined,
                        submittedAt: undefined,
                        hash: undefined,
                    });

                    therapistScheduleTemplates.push({
                        ...item,
                        submitted: true,
                        submittedAt: now,
                        hash,
                    });
                } else {
                    therapistScheduleTemplates.push(item);
                }
            }

            await updateDocument("users", userId, {
                ..._user,
                therapistScheduleTemplates,
            });

            setOpenDialog(true);
        }

        setOpenSubmit(false);
    };

    return (
        <Page>
            <HomeNavbar title={userTemplate.name} />

            <Card header={userTemplate.name}>
                <List nested className="-m-4">
                    <Item name="From" value={formatDate(template.startDate)} />
                    <Item name="To" value={formatDate(template.endDate)} />
                    <Item name="Max Days/Week" value={userTemplate.maxDaysPerWeek} />
                    <Item
                        name="Treatments/Week"
                        value={userTemplate.numberOfSlotsPerWeek}
                    />
                    <Item
                        name="Consecutive Days"
                        value={userTemplate.consecutiveDays ? "Yes" : "No"}
                    />
                </List>
            </Card>

            <Block inset>
                <Button
                    large
                    className="k-color-brand-yellow"
                    onClick={() => {
                        setOpenSubmit(true);
                    }}
                    disabled={closed}
                >
                    Submit Requested Schedule
                </Button>
            </Block>

            {Object.keys(days).map((day) => {
                return (
                    <div key={day}>
                        <Card
                            header={
                                <div className="flex justify-between">
                                    <div className="font-bold">{day}</div>
                                    {days[day].availability === "Available" ? (
                                        <div className="flex space-x-2">
                                            {days[day].Morning?.availability ===
                                            "Available" ? (
                                                <Badge
                                                    colors={{
                                                        bg:
                                                            days[day].Morning.priority ===
                                                            "High"
                                                                ? "bg-green-500"
                                                                : days[day].Morning
                                                                      .priority === "Low"
                                                                ? "bg-red-500"
                                                                : "bg-yellow-500",
                                                    }}
                                                >
                                                    AM
                                                </Badge>
                                            ) : (
                                                <div className="text-sgray-300">AM</div>
                                            )}

                                            {days[day].Afternoon?.availability ===
                                            "Available" ? (
                                                <Badge
                                                    colors={{
                                                        bg:
                                                            days[day].Afternoon.priority ===
                                                            "High"
                                                                ? "bg-green-500"
                                                                : days[day].Afternoon
                                                                      .priority === "Low"
                                                                ? "bg-red-500"
                                                                : "bg-yellow-500",
                                                    }}
                                                >
                                                    AFT
                                                </Badge>
                                            ) : (
                                                <div className="text-sgray-300">AFT</div>
                                            )}

                                            {days[day].Evening?.availability ===
                                            "Available" ? (
                                                <Badge
                                                    colors={{
                                                        bg:
                                                            days[day].Evening.priority ===
                                                            "High"
                                                                ? "bg-green-500"
                                                                : days[day].Evening
                                                                      .priority === "Low"
                                                                ? "bg-red-500"
                                                                : "bg-yellow-500",
                                                    }}
                                                >
                                                    PM
                                                </Badge>
                                            ) : (
                                                <div className="text-gray-400 dark:text-gray-600">
                                                    PM
                                                </div>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            }
                        >
                            {days[day].availability === "Available" ? (
                                <List nested className="-m-4">
                                    <Item
                                        name={day + " Priority"}
                                        value={
                                            days[day].Priority === "High" ? (
                                                <Badge colors={{ bg: "bg-green-500" }}>
                                                    High
                                                </Badge>
                                            ) : days[day].Priority === "Medium" ? (
                                                <Badge colors={{ bg: "bg-yellow-500" }}>
                                                    Medium
                                                </Badge>
                                            ) : (
                                                <Badge colors={{ bg: "bg-red-500" }}>
                                                    Low
                                                </Badge>
                                            )
                                        }
                                    />
                                    <Item
                                        name="Treatments/Day"
                                        value={days[day].SlotsPerDay}
                                    />
                                    <Item
                                        name="Double Shifts"
                                        value={
                                            days[day].MultipleShifts ? (
                                                <Badge colors={{ bg: "bg-green-500" }}>
                                                    Yes
                                                </Badge>
                                            ) : (
                                                <Badge colors={{ bg: "bg-red-500" }}>
                                                    No
                                                </Badge>
                                            )
                                        }
                                    />
                                </List>
                            ) : null}
                        </Card>

                        <Shifts
                            days={days}
                            day={day}
                            therapyRoomMap={therapyRoomMap}
                            partOfDay="Morning"
                        />

                        <Shifts
                            days={days}
                            day={day}
                            therapyRoomMap={therapyRoomMap}
                            partOfDay="Afternoon"
                        />

                        <Shifts
                            days={days}
                            day={day}
                            therapyRoomMap={therapyRoomMap}
                            partOfDay="Evening"
                        />
                    </div>
                );
            })}

            <Block inset>
                <Button
                    large
                    className="k-color-brand-yellow"
                    onClick={() => {
                        setOpenSubmit(true);
                    }}
                    disabled={closed}
                >
                    Submit Requested Schedule
                </Button>
            </Block>

            <Sheet
                className="max-w-[calc(min(100%,460px))] ml-[calc(100%-min(100%,460px))]"
                opened={openSubmit}
                onBackdropClick={() => setOpenSubmit(false)}
            >
                <Toolbar top>
                    <div className="left" />
                    <div className="right">
                        <Link onClick={() => setOpenSubmit(false)}>Cancel</Link>
                    </div>
                </Toolbar>
                <Block>
                    <p>
                        By submitting this schedule, you are confirming that you are
                        available to work on the days and times you have requested.
                    </p>
                    <div className="mt-4 mb-16">
                        <Button
                            large
                            className="k-color-brand-yellow"
                            onClick={submitSchedule}
                        >
                            Submit
                        </Button>
                    </div>
                </Block>
            </Sheet>

            <Dialog
                opened={openDialog}
                onBackdropClick={() => {
                    setOpenDialog(false);
                    navigate(-1);
                }}
                title="Schedule Submitted"
                content="Your schedule has been submitted successfully."
                buttons={
                    <DialogButton
                        onClick={() => {
                            setOpenDialog(false);
                            navigate(-1);
                        }}
                    >
                        Ok
                    </DialogButton>
                }
            />
        </Page>
    );
};

const Item = ({ name, value }) => {
    return <ListItem title={name} after={value} />;
};

Item.propTypes = {
    name: PropTypes.string,
    value: PropTypes.node,
};

const Shifts = ({ days, day, therapyRoomMap, partOfDay }) => {
    if (days[day].availability !== "Available") {
        return null;
    }

    return days[day][partOfDay] &&
        days[day][partOfDay].therapistRequestedScheduleShift &&
        days[day][partOfDay].therapistRequestedScheduleShift.some((s) => s.enabled) ? (
        <Card
            header={
                <div className="font-bold">
                    {day} - {partOfDay}
                </div>
            }
        >
            <List nested className="-m-4">
                {days[day][partOfDay].therapistRequestedScheduleShift.map((shift) => {
                    return shift.enabled ? (
                        <ListItem
                            key={shift.therapyScheduleTemplateItemId}
                            title={
                                <div className="flex space-x-2">
                                    <div className="w-40">
                                        {therapyRoomMap[shift.therapyRoomId].name}
                                    </div>

                                    <div className="w-24 text-sm">
                                        {shift.startTime + " - " + shift.endTime}
                                    </div>

                                    {/*<div className="text-sm">{shift.slots + " slots"}</div>*/}
                                </div>
                            }
                            after={
                                shift.priority === "High" ? (
                                    <Badge
                                        colors={{
                                            bg: "bg-green-500",
                                        }}
                                    >
                                        High
                                    </Badge>
                                ) : shift.priority === "Medium" ? (
                                    <Badge
                                        colors={{
                                            bg: "bg-yellow-500",
                                        }}
                                    >
                                        Medium
                                    </Badge>
                                ) : (
                                    <Badge
                                        colors={{
                                            bg: "bg-red-500",
                                        }}
                                    >
                                        Low
                                    </Badge>
                                )
                            }
                        />
                    ) : null;
                })}
            </List>
        </Card>
    ) : null;
};

Shifts.propTypes = {
    days: PropTypes.object,
    day: PropTypes.string,
    therapyRoomMap: PropTypes.object,
    partOfDay: PropTypes.string,
};

/*
{
  "numberOfSlotsPerWeek": "4",
  "tuesdaySlotsPerDay": "1",
  "fridayMultipleShifts": false,
  "fridaySlotsPerDay": "2",
  "consecutiveDays": true,
  "maxDaysPerWeek": "3",
  "tuesdayMultipleShifts": true,
  "name": "Test Test Sara/Nick",
  "tuesdayPriority": "Medium",
  "mondaySlotsPerDay": "3",
  "therapyScheduleTemplate_link": "666760a33ee34a8a3d7e240d",
  "wednesdaySlotsPerDay": "3",
  "mondayPriority": "High",
  "therapistRequestedSchedule": [
    {
      "dayOfWeek": "Monday",
      "availability": "Available",
      "partOfDay": "Morning",
      "priority": "High"
    },
    {
      "dayOfWeek": "Monday",
      "partOfDay": "Afternoon",
      "availability": "Available"
    },
    {
      "dayOfWeek": "Monday",
      "partOfDay": "Evening",
      "availability": "Not Available"
    },
    {
      "dayOfWeek": "Tuesday",
      "availability": "Available",
      "priority": "High",
      "therapistRequestedScheduleShift": [
        {
          "startTime": "09:30",
          "slots": 2,
          "therapyScheduleTemplateItemId": "6668abe43ee34a8a3d7e268f",
          "enabled": true,
          "partOfDay": "Morning",
          "dayOfWeek": "Tuesday",
          "endTime": "12:30",
          "priority": "High",
          "therapyRoomId": "66144a8b66f2d47b06814569"
        },
        {
          "therapyRoomId": "66144a8b66f2d47b0681456c",
          "dayOfWeek": "Tuesday",
          "partOfDay": "Morning",
          "startTime": "09:30",
          "slots": 2,
          "endTime": "00:30",
          "priority": "High",
          "enabled": true,
          "therapyScheduleTemplateItemId": "6668ac053ee34a8a3d7e26a5"
        }
      ],
      "partOfDay": "Morning"
    },
    {
      "partOfDay": "Afternoon",
      "availability": "Available",
      "therapistRequestedScheduleShift": [
        {
          "partOfDay": "Afternoon",
          "dayOfWeek": "Friday",
          "startTime": "13:00",
          "slots": 2,
          "priority": "High",
          "enabled": true,
          "therapyScheduleTemplateItemId": "6668c4903ee34a8a3d7e2729",
          "therapyRoomId": "66144a8b66f2d47b0681454b",
          "endTime": "16:00"
        },
        {
          "therapyRoomId": "66144a8b66f2d47b0681454e",
          "slots": 2,
          "priority": "High",
          "endTime": "16:00",
          "enabled": true,
          "startTime": "13:00",
          "dayOfWeek": "Friday",
          "therapyScheduleTemplateItemId": "6668c4a33ee34a8a3d7e273c",
          "partOfDay": "Afternoon"
        }
      ],
      "dayOfWeek": "Friday",
      "priority": "High"
    },
    {
      "priority": "High",
      "availability": "Available",
      "dayOfWeek": "Wednesday",
      "therapistRequestedScheduleShift": [
        {
          "enabled": true,
          "startTime": "09:30",
          "partOfDay": "Morning",
          "dayOfWeek": "Wednesday",
          "endTime": "12:30",
          "therapyRoomId": "66144a8b66f2d47b0681454e",
          "priority": "High",
          "therapyScheduleTemplateItemId": "6668ab103ee34a8a3d7e2633",
          "slots": 2
        },
        {
          "priority": "High",
          "slots": 2,
          "endTime": "12:30",
          "therapyScheduleTemplateItemId": "6668abe43ee34a8a3d7e2692",
          "dayOfWeek": "Wednesday",
          "partOfDay": "Morning",
          "therapyRoomId": "66144a8b66f2d47b06814569",
          "startTime": "09:30",
          "enabled": true
        },
        {
          "enabled": true,
          "therapyScheduleTemplateItemId": "6668ac053ee34a8a3d7e26a8",
          "partOfDay": "Morning",
          "startTime": "09:30",
          "dayOfWeek": "Wednesday",
          "priority": "Low",
          "endTime": "00:30",
          "therapyRoomId": "66144a8b66f2d47b0681456c",
          "slots": 2
        },
        {
          "enabled": true,
          "partOfDay": "Morning",
          "startTime": "09:30",
          "endTime": "12:30",
          "priority": "Low",
          "dayOfWeek": "Wednesday",
          "therapyRoomId": "66144a8b66f2d47b0681454b",
          "slots": 2,
          "therapyScheduleTemplateItemId": "666b36443ee34a8a3d7ec9f1"
        }
      ],
      "partOfDay": "Morning"
    },
    {
      "priority": "Low",
      "availability": "Available",
      "partOfDay": "Afternoon",
      "dayOfWeek": "Wednesday"
    },
    {
      "partOfDay": "Evening",
      "availability": "Available",
      "dayOfWeek": "Wednesday"
    },
    {
      "availability": "Available",
      "dayOfWeek": "Sunday",
      "partOfDay": "Morning"
    },
    {
      "dayOfWeek": "Sunday",
      "availability": "Available",
      "partOfDay": "Afternoon"
    },
    {
      "dayOfWeek": "Sunday",
      "partOfDay": "Evening",
      "availability": "Available"
    },
    {
      "availability": "Available",
      "dayOfWeek": "Saturday",
      "partOfDay": "Morning"
    },
    {
      "partOfDay": "Afternoon",
      "availability": "Available",
      "dayOfWeek": "Saturday"
    },
    {
      "dayOfWeek": "Saturday",
      "partOfDay": "Evening",
      "availability": "Available"
    },
    {
      "partOfDay": "Morning",
      "therapistRequestedScheduleShift": [
        {
          "slots": 2,
          "priority": "High",
          "enabled": true,
          "startTime": "09:30",
          "therapyRoomId": "66144a8b66f2d47b0681454b",
          "dayOfWeek": "Friday",
          "therapyScheduleTemplateItemId": "6668aaf93ee34a8a3d7e2626",
          "endTime": "12:30",
          "partOfDay": "Morning"
        },
        {
          "therapyRoomId": "66144a8b66f2d47b0681454e",
          "dayOfWeek": "Friday",
          "endTime": "12:30",
          "startTime": "09:30",
          "therapyScheduleTemplateItemId": "6668ab103ee34a8a3d7e2639",
          "enabled": true,
          "slots": 2,
          "partOfDay": "Morning",
          "priority": "High"
        },
        {
          "therapyScheduleTemplateItemId": "6668abe43ee34a8a3d7e2698",
          "startTime": "09:30",
          "priority": "Low",
          "endTime": "12:30",
          "therapyRoomId": "66144a8b66f2d47b06814569",
          "partOfDay": "Morning",
          "dayOfWeek": "Friday",
          "enabled": true,
          "slots": 2
        },
        {
          "startTime": "09:30",
          "partOfDay": "Morning",
          "slots": 2,
          "endTime": "00:30",
          "dayOfWeek": "Friday",
          "priority": "Low",
          "enabled": true,
          "therapyRoomId": "66144a8b66f2d47b0681456c",
          "therapyScheduleTemplateItemId": "6668ac063ee34a8a3d7e26ae"
        }
      ],
      "dayOfWeek": "Friday",
      "availability": "Available"
    },
    {
      "dayOfWeek": "Friday",
      "availability": "Available",
      "partOfDay": "Evening"
    },
    {
      "dayOfWeek": "Thursday",
      "availability": "Available",
      "partOfDay": "Morning"
    },
    {
      "availability": "Available",
      "dayOfWeek": "Thursday",
      "partOfDay": "Afternoon"
    },
    {
      "dayOfWeek": "Thursday",
      "partOfDay": "Evening",
      "availability": "Available"
    }
  ],
  "fridayPriority": "Low",
  "wednesdayMultipleShifts": true,
  "saturdayMultipleShifts": true,
  "wednesdayPriority": "High",
  "mondayMultipleShifts": true,
  "saturdayPriority": "High"
}
*/

const getDays = (userTemplate) => {
    const days = {};

    const daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    daysOfWeek.forEach((day) => {
        days[day] = {};

        days[day].SlotsPerDay = userTemplate[day.toLowerCase() + "SlotsPerDay"]
            ? userTemplate[day.toLowerCase() + "SlotsPerDay"]
            : 0;

        days[day].MultipleShifts = userTemplate[day.toLowerCase() + "MultipleShifts"]
            ? userTemplate[day.toLowerCase() + "MultipleShifts"]
            : false;

        days[day].Priority = userTemplate[day.toLowerCase() + "Priority"]
            ? userTemplate[day.toLowerCase() + "Priority"]
            : "Low";

        if (userTemplate.therapistRequestedSchedule) {
            userTemplate.therapistRequestedSchedule
                .filter((item) => item.dayOfWeek === day)
                .forEach((item) => {
                    days[day][item.partOfDay] = item;
                });
        }

        // set the availability for the day (if one of the parts of the day is available, the day is available)
        days[day].availability = "Not Available";

        if (days[day].Morning && days[day].Morning.availability === "Available") {
            days[day].availability = "Available";
        } else if (
            days[day].Afternoon &&
            days[day].Afternoon.availability === "Available"
        ) {
            days[day].availability = "Available";
        } else if (days[day].Evening && days[day].Evening.availability === "Available") {
            days[day].availability = "Available";
        }
    });

    return days;
};

export default ViewAndSubmit;
