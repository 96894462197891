import {
    doc,
    setDoc,
    collection,
    addDoc,
    getDoc,
    getDocs,
    updateDoc,
} from "firebase/firestore";

import firebase from "./firebase";

const { db } = firebase;

export const createUser = async (uid, data) => {
    const ref = doc(db, "users", uid);
    const result = await setDoc(ref, { uid, ...data }, { merge: true });

    return result;
};

export const readDocuments = async (_collection) => {
    const ref = collection(db, _collection);
    const querySnapshot = await getDocs(ref);

    const docs = [];
    querySnapshot.forEach((doc) => {
        docs.push(doc.data());
    });

    return docs;
};

export const readDocument = async (_collection, id) => {
    const ref = doc(db, _collection, id);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
};

export const updateDocument = async (_collection, id, data) => {
    const ref = doc(db, _collection, id);
    await updateDoc(ref, data);
};

export const createDocument = async (_collection, data) => {
    const ref = collection(db, _collection);
    const result = await addDoc(ref, data);

    return result.id;
};

export const createDocumentWithId = async (_collection, id, data) => {
    const ref = doc(db, _collection, id);
    await setDoc(ref, data);

    return id;
};
